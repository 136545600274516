import React, { useEffect, useState } from 'react';
import { Typography, Container, List, ListItem, Box } from '@mui/material';
import { getMultipleInfoSessionByIds } from '../../../../services/infoSession.service';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
const tz = 'America/New_York';
dayjs.tz.setDefault(tz);

import { InfoSessionEditModel } from '../../../../store/infoSession/infoSessionModel';

const Confirmation = (props: any) => {
  const { infoSessionIds, firstName } = props;
  const [infoSessionDetail, setInfoSessionDetail] = useState<InfoSessionEditModel[]>([]);
  useEffect(() => {
    (async () => {
      if (infoSessionIds) {
        const info: any = await getMultipleInfoSessionByIds(infoSessionIds);
        setInfoSessionDetail(info);
      }
    })();
  }, [infoSessionIds]);

  return (
    <Container>
      <Box style={{ padding: '20px', marginTop: '40px' }}>
        <Typography variant='h5' gutterBottom>
          Congratulations, {firstName}! You have successfully registered for the following PINCC Info Sessions:
        </Typography>

        <List>
          {infoSessionDetail?.map((item: any, index: any) => (
            <ListItem key={index}>
              <Typography variant='body1' gutterBottom>
                Info Session {index + 1} - Date:{' '}
                {dayjs(item.dateTime).tz('America/New_York').format('MMMM DD, YYYY, HH:mm')}
              </Typography>
            </ListItem>
          ))}
        </List>

        <Typography variant='h6' sx={{ my: 2 }}>
          {`What's Next?`}
        </Typography>

        <Typography variant='body1' paragraph>
          {`If you've selected an in-person Info Session, you will receive an email with the session date and location
          shortly.`}
        </Typography>

        <Typography variant='body1' paragraph>
          {`For virtual Info Sessions, you will receive an email with the session link`}
        </Typography>

        <Typography variant='body1' paragraph>
          {`Additionally, you will receive a reminder email 24 hours before the session starts to ensure you don't miss
          it.`}
        </Typography>

        <Typography variant='body1' paragraph>
          Thank you for choosing PINCC, and we look forward to seeing you at the Info Sessions!
        </Typography>
      </Box>
    </Container>
  );
};

export default Confirmation;
