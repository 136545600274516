import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  Box,
  Button,
  Popover,
  Paper,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  List,
  ListItem,
  Avatar,
  Badge,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CircularProgress from '@mui/material/CircularProgress';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { COLORS } from '../../../utils/colors';
import { UserModel } from '../../../store/user/userModel';
import { signOut } from 'firebase/auth';
import { auth } from '../../../config/firebaseApp';
import {
  listenForUserNotificationsByUserId,
  markSingleNotification,
  markAllNotification,
} from '../../../services/firebase.service';
import { logoutUser } from '../../../store/user/userAction';
// eslint-disable-next-line
import { AccountCircleOutlined, ChatOutlined, NotificationsOutlined, SettingsOutlined } from '@mui/icons-material';
import { getChatMessagesByChannelId } from '../../../services/chatMessages.service';
import { getChatChannelsById } from '../../../services/chatChanels.service';
// eslint-disable-next-line
import { convertTimeStampToDate } from '../../../utils/dateTime';
import { UserRolesEnum } from '../../../utils/rolesEnum';
import GenericDialog from '../../Dialog/genericDialog/genericDialog';

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user: UserModel = useAppSelector((state: any) => state.user.user);
  const userRole: string = user ? user.role : '';
  // eslint-disable-next-line
  const avatarIcon = 'https://cdn-icons-png.flaticon.com/128/3899/3899618.png';

  const [readLoading, setReadLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [notificationsList, setNotificationsList] = useState<any[]>([]);

  const [notificationEl, setNotificationEl] = useState<HTMLButtonElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // eslint-disable-next-line
  const [anchorElMalert, setAnchorElMalert] = useState<null | HTMLElement>(null);

  const [channelsList, setChannelsList] = useState<any[]>([]);
  const [messagesList, setMessagesList] = useState<any[]>([]);
  const [markAllAlert, setMarkAllAlert] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setNotificationEl(event.currentTarget);
  };

  const handleClose = () => {
    setNotificationEl(null);
  };

  const markAllAsRead = async () => {
    if (user && user?.id) {
      try {
        setReadLoading(true);
        await markAllNotification(user.id);
        setReadLoading(false);
      } catch (err: any) {
        toast.error('Error Marking Notification As Read!');
        setReadLoading(false);
      }
    }
  };

  const markSingleNotificationAsRead = async (docId: string) => {
    setLoading(true);
    await markSingleNotification(docId);
    setLoading(false);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // eslint-disable-next-line
  const handleMalertMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElMalert(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // eslint-disable-next-line
  const handleMalertMenuClose = () => {
    setAnchorElMalert(null);
  };

  const handleLogout = async () => {
    dispatch(logoutUser());
    signOut(auth);
    handleMenuClose();
  };

  useEffect(() => {
    const unsubscribe = getChatChannelsById(user.id || '', (data: any) => {
      setChannelsList(data);
    });
    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const unsubscribe = listenForUserNotificationsByUserId(user.id || '', (notifications: any) => {
      setNotificationsList(notifications);
    });

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line
  const fetchChatMessages = () => {
    let list: any[] = [...messagesList];
    const unsubscribeFunctions = channelsList.map((item) => {
      return getChatMessagesByChannelId(item.channelInfo?.id, (data: any) => {
        const msgData = data
          ?.filter((i: any) => i.senderId !== user.id)
          .map((dt: any) => {
            return { channelInfo: item.channelInfo, messageInfo: dt, senderInfo: item.senderInfo };
          });

        if (msgData) {
          msgData.forEach((item: any) => {
            const msgIndex = list.findIndex((i: any) => i.messageInfo.id === item.messageInfo.id);

            if (msgIndex === -1 && item.messageInfo.isRead === false) {
              list.push(item);
            } else {
              if (msgIndex !== -1 && item.messageInfo.isRead === true) {
                list = list.filter((v: any) => v.messageInfo.id !== item.messageInfo.id);
              }
            }
          });
        }
        setMessagesList([...list]);
      });
    });

    return () => {
      unsubscribeFunctions.forEach((unsubscribe) => unsubscribe());
    };
  };

  useEffect(() => {
    const unsubscribe = fetchChatMessages();
    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line
  }, [channelsList.length > 0]);

  return (
    <Box sx={styles.dashboardNav}>
      <Typography variant='h3' sx={styles.welcomeMsgText}>
        Welcome, {user ? user.firstName + '!' : ''}
      </Typography>
      <Box sx={styles.navbarContainer}>
        {/* Message Alert Button */}
        <IconButton onClick={handleMalertMenuOpen}>
          <Badge badgeContent={messagesList.length} color='error'>
            <ChatOutlined style={styles.icon} />
          </Badge>
        </IconButton>
        <Menu
          anchorEl={anchorElMalert}
          open={Boolean(anchorElMalert)}
          onClose={handleMalertMenuClose}
          PaperProps={{
            style: {
              width: 300,
            },
          }}>
          {messagesList.length > 0 ? (
            messagesList.map((item, index) => (
              <MenuItem
                onClick={() => {
                  navigate('/admin/clientInfo', {
                    state: { clientId: item?.senderInfo.id, activeChannelDetails: item, activeTabIndex: 2 },
                  });
                }}
                key={index}>
                <Box pr={2}>
                  <Avatar src={avatarIcon} sx={{ width: 24, height: 24 }} />
                </Box>
                <Box flexDirection={'column'} display={'flex'}>
                  <Typography variant='caption' fontWeight={'bold'}>
                    {item?.senderInfo.firstName + ' ' + item?.senderInfo.lastName}
                  </Typography>
                  <Typography variant='caption'>{item?.messageInfo.text}</Typography>
                  <Typography variant='caption'>
                    {convertTimeStampToDate(item?.messageInfo.timestamp).format('MMMM DD, YYYY hh:mm:ss')}
                  </Typography>
                </Box>
              </MenuItem>
            ))
          ) : (
            <Typography sx={styles.unreadMsgText}>{'No unread messages yet.'}</Typography>
          )}
        </Menu>

        {/* Notifications button with dropdown */}
        <IconButton onClick={handleClick}>
          <Badge badgeContent={notificationsList.length} color='error'>
            <NotificationsOutlined style={styles.icon} />
          </Badge>
        </IconButton>
        <Popover
          open={Boolean(notificationEl)}
          anchorEl={notificationEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}>
          <Box>
            <Paper sx={styles.notificationContainer} elevation={2}>
              <Typography sx={styles.notificationText}>{'Notifications'}</Typography>
              {readLoading ? (
                <CircularProgress />
              ) : (
                <Button
                  variant='text'
                  size='small'
                  sx={{ fontSize: '10px' }}
                  onClick={() => setMarkAllAlert(true)}
                  disabled={!notificationsList?.length}>
                  {'Mark all as read'}
                </Button>
              )}
            </Paper>
            <Box sx={styles.notificationBox}>
              {loading ? <CircularProgress /> : null}
              {notificationsList?.length ? (
                notificationsList.map((item, index) => {
                  return (
                    <Box key={index.toString()} onClick={() => null} sx={{ marginBottom: '20px', cursor: 'pointer' }}>
                      <List>
                        <ListItem disablePadding sx={styles.list}>
                          {item?.title}
                        </ListItem>
                      </List>
                      <Typography style={styles.listDesc}>{item?.description}</Typography>
                      <Button
                        variant='text'
                        onClick={() => markSingleNotificationAsRead(item.id)}
                        sx={[styles.button, { left: 150, marginRight: 20 }]}>
                        Mark as read
                      </Button>
                    </Box>
                  );
                })
              ) : (
                <Typography sx={styles.unreadMsgText}>{'empty notification box'}</Typography>
              )}
            </Box>
          </Box>
        </Popover>

        {/* Settings button */}
        {userRole === UserRolesEnum.SUPER_ADMIN ? (
          <IconButton onClick={() => navigate('/admin/setting')}>
            <SettingsOutlined style={styles.icon} />
          </IconButton>
        ) : null}

        {/* User Profile */}
        <Box sx={styles.userSpan}>
          <IconButton size='small' disableRipple onClick={handleMenuOpen}>
            <AccountCircleOutlined />
            <Typography variant='button' textTransform={'none'} ml={0.5}>
              {user ? user.firstName : ''}
            </Typography>
            <KeyboardArrowDownIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}>
            <MenuItem onClick={handleLogout}>Log out</MenuItem>
          </Menu>
        </Box>
        <GenericDialog
          onClose={() => setMarkAllAlert(false)}
          dialogTitle='Are you sure to mark all notifications as read?'
          agreeBtnText='Mark As Read'
          disagreeBtnText='Cancel'
          agreeFunc={() => markAllAsRead()}
          open={markAllAlert}
        />
      </Box>
    </Box>
  );
};

export default Navbar;

const styles = {
  welcomeMsgText: {
    fontSize: '18px',
    color: '#424242',
    fontWeight: 'bold',
  },
  navbarContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    fontSize: '22px',
    color: '#616161',
  },
  unreadMsgText: {
    textAlign: 'center',
    fontSize: '10px',
  },
  notificationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px',
    alignItems: 'center',
  },
  notificationText: {
    fontSize: '15px',
    fontWeight: 'bold',
  },
  list: {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '20px',
  },
  listDesc: {
    fontWeight: 400,
    fontSize: '11px',
    lineHeight: '10px',
    color: COLORS.palette.gray,
  },
  dashboardNav: {
    px: 5,
    py: 2,
    width: '85%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: COLORS.palette.white,
    boxShadow: '0px 5px 10px 1px rgba(145,145,145,0.76)',
    position: 'fixed',
    zIndex: 888,
  },
  userSpan: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '18px',
    color: '#424242',
    cursor: 'pointer',
  },
  button: {
    fontSize: 10,
  },
  notificationBox: {
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
};
