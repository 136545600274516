import React, { useEffect, useState } from 'react';
import { Box, Chip, Grid, ListItem, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import {
  IResendInvitationMail,
  getLiveClientInfoById,
  resendInvitationMailToPC,
} from '../../../../services/client.service';
import { Loader } from '../../../../components/Loader/Loader';
import { Palette } from '../../../../utils/palette';
import { ClientInfoForm } from '../../../../components/ClientInfoForm/ClientInfoForm';
import { LoadingButton } from '../../../../components/LoadingButton/LoadingButton';
import { ConvertProspectiveClient } from '../../../../components/ConvertProspectiveClient/ConvertProspectiveClient';
import { toast } from 'react-toastify';
import { useAppSelector } from '../../../../store/store';
import { UserModel } from '../../../../store/user/userModel';

const ProspectiveClientsInfo = () => {
  const user: UserModel = useAppSelector((state: any) => state.user.user);
  const { clientId } = useLocation().state;
  const [clientInfo, setClientInfo] = useState<any>();
  const [isClientUpdated, setIsClientUpdated] = useState(false);
  const [showConvertProspectiveClientModal, setShowConvertProspectiveClientModal] = useState(false);

  useEffect(() => {
    (async () => {
      let client: any = null;
      const unsubscribe = await getLiveClientInfoById(clientId, async (data: any) => {
        client = data;
        setClientInfo(client);
      });

      return () => {
        unsubscribe();
      };
    })();
    // eslint-disable-next-line
  }, [clientId]);

  //initial loader while page loading
  if (!clientInfo) {
    return <Loader />;
  }

  const handleButtonClick = async () => {
    try {
      const payload: IResendInvitationMail = {
        firstName: clientInfo.firstName,
        email: clientInfo.email,
        infoSessionIds: clientInfo.infoSessionIds,
      };
      await resendInvitationMailToPC(payload, user?.role);
      toast.success('Invitation mail sent successfully!');
    } catch (err: any) {
      toast.error(err.message);
    }
  };
  return (
    <>
      <Grid container sx={styles.centerGrid} alignItems={'center'}>
        <Grid item display={{ lg: 'flex', md: 'flex' }} alignItems={'center'} mt={3}>
          <Typography variant='h5' fontWeight={500}>
            {clientInfo?.firstName + ' ' + clientInfo?.middleName + ' ' + clientInfo?.lastName}
          </Typography>
          {/* Client Staus Badge */}
          <ListItem sx={{ width: 'fit-content' }}>
            <Chip
              size='small'
              sx={{
                background: Palette.green_E4F3E5,
                color: Palette.green_4CAF50,
              }}
              label={
                <Typography variant='button' sx={styles.customChipLabel}>
                  {String(clientInfo?.status)}
                </Typography>
              }
            />
          </ListItem>
        </Grid>
        <Box display={'inline'}>
          <Grid item display={'flex'} mt={3}>
            <Box>
              <LoadingButton
                label='Convert to Client'
                onClick={() => {
                  setShowConvertProspectiveClientModal(true);
                }}
                size='small'
                variant='contained'
                styles={{ textTransform: 'capitalize' }}
              />
            </Box>
            <Box ml={1}>
              <LoadingButton
                label='Resend Invitation Mail'
                onClick={handleButtonClick}
                size='small'
                variant='contained'
                styles={{ textTransform: 'capitalize' }}
              />
            </Box>
          </Grid>
          <Typography color={'error'} mt={1}>{`(Please review all data before converting to a client)`}</Typography>
        </Box>
        <Grid item lg={12} xs={12} mt={2}>
          <ClientInfoForm
            client={clientInfo}
            onSubmit={() => setIsClientUpdated(!isClientUpdated)}
            isProspectiveClientInfo={true}
          />
        </Grid>
      </Grid>
      <ConvertProspectiveClient
        id={clientInfo?.id}
        dob={clientInfo?.dob}
        open={showConvertProspectiveClientModal}
        onClose={() => setShowConvertProspectiveClientModal(!showConvertProspectiveClientModal)}
        onSubmit={(val: boolean) => {
          setShowConvertProspectiveClientModal(val);
        }}
      />
    </>
  );
};

export default ProspectiveClientsInfo;

const styles = {
  customChipLabel: {
    fontSize: '10px',
    textTransform: 'uppercase',
    lineHeight: 1.5,
  },
  centerGrid: {
    justifyContent: {
      lg: 'space-between',
      md: 'flex-start',
    },
  },
  listStyle: {
    display: {
      lg: 'flex',
      md: 'flex',
    },
  },
  listItemStyle: {
    width: 'fit-content',
    pr: {
      lg: 4,
      md: 4,
    },
  },
};
