import React, { useState } from 'react';
import { Box } from '@mui/material';
import { Navigate, Outlet } from 'react-router-dom';
import ClientNavbar from '../ClientNavbar/ClientNavbar';
import { useAuthStatus } from '../../hooks/useAuthStatus';
import Loading from '../loading/loading';
import { UserRolesEnum } from '../../utils/rolesEnum';
import ClientSidebar from '../Sidebar/ClientSidebar';
import { UserModel } from '../../store/user/userModel';
import { useAppSelector } from '../../store/store';
import { ClientSidebarMenuItems } from '../../utils/clientSidebar';
import { ProspectiveClientSidebarMenuItems } from '../../utils/prospectiveClientSidebar';

const ClientLayout = () => {
  const { loggedInRole, checkingStatus } = useAuthStatus();
  const [sideBarVisible, setSideBarVisible] = useState(false);
  const user: UserModel = useAppSelector((state: any) => state.user.user);
  if (checkingStatus) return <Loading />;
  if (!loggedInRole) return <Navigate to='/login' />;

  return (
    <Box display={'flex'} width={'100%'} height={'100%'} position={'relative'}>
      <ClientSidebar
        onClose={() => {
          setSideBarVisible(false);
        }}
        open={sideBarVisible}
        sidebarMenuItems={
          UserRolesEnum.PROSPECTIVE_CLIENT === loggedInRole ? ProspectiveClientSidebarMenuItems : ClientSidebarMenuItems
        }
      />

      <Box width={'100%'} position={'relative'} bgcolor={'#fff'}>
        <ClientNavbar
          title={user ? user?.firstName + ' ' + user?.lastName : ''}
          toggelSidebar={() => {
            setSideBarVisible(!sideBarVisible);
          }}
        />
        <Box>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default ClientLayout;
