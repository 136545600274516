import React from 'react';
import { Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { infoSessionTypes } from '../../../utils/infoSessionTypeEnums';
import { InfoSessionEditModel } from '../../../store/infoSession/infoSessionModel';

interface Iprops {
  infoSessionData: InfoSessionEditModel;
}

const ClientInfoSessionDetail = (props: Iprops) => {
  const { infoSessionData } = props;

  const infoSessionDetail = [
    {
      primary: 'Date & Time: ',
      secondary: dayjs(infoSessionData.dateTime).tz('America/New_York').format('MMMM DD, YYYY, hh:mm A'),
    },
    { primary: 'Program: ', secondary: infoSessionData.title },
  ];
  if (infoSessionData.sessionType === infoSessionTypes.IN_PERSON) {
    infoSessionDetail.push({ primary: 'Location: ', secondary: infoSessionData.location });
  }

  return (
    <Grid container p={2}>
      <Typography mb={1} variant='h6'>
        Info Session Details
      </Typography>
      <Grid item xs={12}>
        <ul>
          {infoSessionDetail.map((item, index) => (
            <li key={index} style={{ marginTop: 4, marginBottom: 4 }}>
              <strong>{item.primary}</strong> {item.secondary}
            </li>
          ))}
        </ul>
      </Grid>
    </Grid>
  );
};

export default ClientInfoSessionDetail;
