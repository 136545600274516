import { Box, Button, Grid, IconButton, Typography, Chip } from '@mui/material';
import { useState, useEffect } from 'react';
import AddNote from './AddNote';
import { useAppSelector } from '../../store/store';
import { UserModel } from '../../store/user/userModel';
import { AddCircleOutlineOutlined, DeleteOutline, Edit } from '@mui/icons-material';
import { addNote, editNote, getAllNotes } from '../../services/client.service';
import { deleteNote } from '../../services/client.service';
import { toast } from 'react-toastify';
import { convertTimeStampToDate } from '../../utils/dateTime';
import GenericDialog from '../Dialog/genericDialog/genericDialog';
import { COLORS } from '../../utils/colors';
import { DropoutConst } from '../../utils/dropoutReasons';
import { getNotesCategoryColors } from '../../pages/admin/client/utils';
import { shouldShowButton } from '../../pages/admin/dashboard/dashboardAccessControl';

interface ClientNotesProps {
  clientId: string;
  clientStatus?: string;
  isReportPage?: boolean;
}

export const ClientNotes = (props: ClientNotesProps) => {
  const user: UserModel = useAppSelector((state: any) => state.user.user) || {};
  const { clientId, isReportPage } = props;
  const [showModal, setShowModal] = useState(false);
  const [notes, setNotes] = useState<any[]>([]);
  const [singleNote, setSingleNote] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [deleteNoteId, setDeleteNoteId] = useState(String);
  const isValidUser = shouldShowButton('accessNotes', user.role);

  const handleSubmit = async (values: any) => {
    let payload = { ...values };
    payload.clientId = clientId;
    payload.role = user?.role;
    setLoading(true);
    if (values.noteId) {
      await editNote(payload);
    } else {
      payload.createdBy = user?.id;
      delete payload.noteId;
      await addNote(payload);
    }
    setLoading(false);
    setSingleNote({});
    setShowModal(false);
    getNotes();
  };

  const getNotes = async () => {
    if (clientId) {
      const unsubscribe: any = await getAllNotes(clientId, (data: any) => {
        setNotes(data);
      });
      return () => {
        unsubscribe();
      };
    }
  };

  useEffect(() => {
    (async () => {
      await getNotes();
    })();
    // eslint-disable-next-line
  }, [clientId]);

  const onClickDelete = async () => {
    try {
      await deleteNote(clientId, deleteNoteId);
      await getNotes();
    } catch (e) {
      toast.error('Something went wrong.');
    }
  };

  return (
    <>
      <Box mt={2}>
        {/* Add Note button */}
        {isValidUser && !isReportPage ? (
          <Box display='flex' justifyContent='flex-end'>
            <Button
              size='small'
              variant='outlined'
              startIcon={<AddCircleOutlineOutlined />}
              onClick={() => {
                setShowModal(true);
              }}>
              Add Notes
            </Button>
          </Box>
        ) : null}

        {/* Notes list */}
        <Box mt={3}>
          {notes?.length ? (
            <Box height='100%' overflow='hidden'>
              <Grid container spacing={1}>
                {notes?.map((item: any) => (
                  <Grid item xs={12} lg={12} key={item.noteId}>
                    <Box py={1} px={2} border='1px solid #ddd' borderRadius={'10px'}>
                      <Box display='flex' justifyContent='space-between' alignItems='center'>
                        <Box display='flex'>
                          <Typography
                            variant='h6'
                            sx={{
                              color: item.title === DropoutConst.DROPOUT ? COLORS.palette.red : COLORS.palette.black,
                              mr: 2,
                            }}>
                            {item.title}
                          </Typography>
                          {item.category ? (
                            <Chip
                              size='small'
                              sx={{
                                background: getNotesCategoryColors(item.category)?.bgColor,
                                color: getNotesCategoryColors(item.category)?.textColor,
                                marginTop: '5px',
                              }}
                              label={
                                <Typography
                                  variant='button'
                                  sx={{
                                    fontSize: '10px',
                                    textTransform: 'uppercase',
                                    lineHeight: 1.5,
                                  }}>
                                  {item.category}
                                </Typography>
                              }
                            />
                          ) : null}
                        </Box>
                        <Box>
                          <Typography variant='body2'>{`Added on ${convertTimeStampToDate(item.createdAt).format(
                            'MM/DD/YYYY'
                          )}`}</Typography>

                          <Typography variant='body2'>Added by {item.role}</Typography>
                          <Typography variant='body2'>{item.creatorFullName || ''}</Typography>
                        </Box>
                      </Box>
                      <Box mt={1} display='flex' alignItems='flex-start'>
                        <Typography variant='body1'>{item.description}</Typography>
                        {item.createdBy === user?.id && !isReportPage ? (
                          <Box display='flex' alignItems='center' gap='16px' ml='auto'>
                            {/* Edit note */}
                            <IconButton
                              type='submit'
                              color='primary'
                              onClick={(e) => {
                                e.preventDefault();
                                setSingleNote({
                                  noteId: item.noteId,
                                  title: item.title,
                                  description: item.description,
                                  category: item?.category || '',
                                });
                                setShowModal(true);
                              }}>
                              <Edit />
                            </IconButton>

                            {/* Delete note */}
                            <IconButton
                              type='submit'
                              color='error'
                              onClick={(e) => {
                                e.preventDefault();
                                setDeleteNoteId(item.noteId);
                                setDeleteAlert(true);
                              }}>
                              <DeleteOutline />
                            </IconButton>
                          </Box>
                        ) : null}
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          ) : (
            <Box p={2}>
              <Typography variant='h6' textAlign={'center'}>
                No notes found.
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      {/* Add Note Modal */}
      {showModal ? (
        <AddNote
          open={showModal}
          onClose={() => {
            setShowModal(false);
            setSingleNote({});
          }}
          onSubmit={(val: any) => {
            handleSubmit(val);
          }}
          loading={loading}
          initialData={singleNote}
        />
      ) : null}

      {/* Delete Confirm Modal */}
      <GenericDialog
        onClose={() => setDeleteAlert(false)}
        dialogTitle='Are you sure?'
        agreeBtnText='Delete'
        disagreeBtnText='Cancel'
        agreeFunc={() => onClickDelete()}
        open={deleteAlert}
      />
    </>
  );
};
