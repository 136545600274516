interface IReportOptions {
  id: number;
  title: string;
  value?: string;
}

export const ReportUtils: IReportOptions[] = [
  {
    id: 1,
    title: 'Case Load Report',
  },
  {
    id: 2,
    title: 'Full Client Profile Report',
  },
  {
    id: 3,
    title: 'Employment Pipeline Report',
  },
  {
    id: 4,
    title: 'Program Track Client Progress Report',
  },
  {
    id: 5,
    title: 'Full attendance report',
  },
];

export const EmpPipeline: IReportOptions[] = [
  {
    id: 1,
    title: 'Monthly',
    value: 'month',
  },
  {
    id: 2,
    title: 'Quarter',
    value: 'quarter',
  },
];

export const caseLoadRoles: IReportOptions[] = [
  {
    id: 1,
    title: 'Navigators',
  },
  {
    id: 2,
    title: 'Employment Liaisons',
  },
];
