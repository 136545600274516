import { Box, List, ListItem, ListItemText } from '@mui/material';
import dayjs from 'dayjs';
import { IJobInfo, ITiming } from '../../store/employmentModule/employmentModal';

interface JobBasicInfoProps {
  jobInfo: IJobInfo;
}

const JobBasicInfo = (props: JobBasicInfoProps) => {
  const { jobInfo } = props;

  const formatDates = (dates: Array<ITiming>) => {
    const formattedDates = dates.map((item: ITiming) => {
      return dayjs(item.timing).tz('America/New_York').format('MM/DD/YYYY, HH:mm');
    });
    return formattedDates.join(' | ');
  };

  const renderListItem = (primary: string, secondary: string | number) => (
    <ListItem disablePadding key={primary}>
      <ListItemText
        primary={primary}
        secondary={secondary}
        primaryTypographyProps={styles.listItemPrimaryText}
        sx={styles.listItemContainer}
      />
    </ListItem>
  );

  const jobDetail = [
    { primary: 'Title: ', secondary: jobInfo.title, typographyProps: styles.jobTitle },
    { primary: 'Description: ', secondary: jobInfo.description, typographyProps: styles.jobDescription },
    { primary: 'Employer: ', secondary: jobInfo.employer },
    { primary: 'Job Type: ', secondary: jobInfo.type },
    { primary: 'Salary: ', secondary: jobInfo.salary },
    { primary: 'No. of Clients: ', secondary: jobInfo.numApplicants || 0 },
    { primary: 'Notes: ', secondary: jobInfo.notes },
    { primary: 'Available Interview Dates: ', secondary: formatDates(jobInfo.availableTimings) },
  ];
  return (
    <Box>
      <List>{jobDetail.map((item) => renderListItem(item.primary, item.secondary))}</List>
    </Box>
  );
};

export default JobBasicInfo;

const styles = {
  listItemContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  listItemPrimaryText: {
    fontWeight: 'bold',
    paddingRight: 1,
    alignSelf: 'flex-start',
  },
  jobTitle: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  jobDescription: {
    fontSize: 16,
  },
};
