import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { COLORS } from '../../utils/colors';
import { IScheduledJobDetail } from './ClientsScheduledInterviews';
import { ITiming } from '../../store/employmentModule/employmentModal';
import dayjs from 'dayjs';
import { LoadingButton } from '../LoadingButton/LoadingButton';
import { rescheduleJobInterview } from '../../services/employmentModule.service';

interface IAddRescheduleProps {
  open: boolean;
  onClose: Function;
  jobDetail: IScheduledJobDetail;
  clientInfo: any;
}

const AddRescheduleInterviewModal = (props: IAddRescheduleProps) => {
  const { open, onClose, jobDetail, clientInfo } = props;
  const [availableTimings, setAvailableTimings] = useState<string[]>([]);
  const [selectedAvlTimings, setSelectedAvlTimings] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const handleAvlTimingToggle = (timing: string) => {
    if (selectedAvlTimings.includes(timing)) {
      setSelectedAvlTimings(selectedAvlTimings.filter((i) => i !== timing));
      handleClose();
    } else {
      setSelectedAvlTimings([...selectedAvlTimings, timing]);
    }
  };

  useEffect(() => {
    if (jobDetail) {
      setAvailableTimings(jobDetail.availableTimings.map((item: ITiming) => item.timing));
    }
  }, [jobDetail]);

  const handleClose = () => {
    onClose();
    setSelectedAvlTimings([]);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await rescheduleJobInterview(clientInfo?.id, jobDetail?.id, selectedAvlTimings);
      handleClose();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // catch error
    }
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={() => handleClose()}
      PaperProps={{ sx: { borderRadius: '14px', width: '20%' } }}>
      <DialogTitle height={70} borderBottom={`1px solid ${COLORS.palette.borderColor}`}>
        <Grid container direction='row' justifyContent='space-between' alignItems='center' marginBottom='20px'>
          <Typography variant='h6'>Reschedule Interview</Typography>
          <IconButton onClick={() => handleClose()}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {availableTimings.length > 0 && (
          <>
            <FormControl>
              <List>
                {availableTimings.map((option: string) => {
                  const labelId = `checkbox-list-secondary-label-${option}`;
                  const isChecked = selectedAvlTimings.includes(option);

                  return (
                    <ListItem key={option} disablePadding>
                      <Checkbox
                        size='small'
                        onChange={() => handleAvlTimingToggle(option)}
                        checked={isChecked}
                        sx={{ alignSelf: 'flex-start' }}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                      <ListItemText
                        id={labelId}
                        primary={dayjs(option).tz('America/New_York').format('MM/DD/YYYY hh:mm A')}
                        sx={{ fontSize: '16px' }}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </FormControl>
            <Grid container justifyContent={'center'}>
              <Grid item>
                <LoadingButton
                  onClick={() => handleSubmit()}
                  label={'Submit'}
                  loading={loading}
                  disabled={selectedAvlTimings.length < 1}
                  size='small'
                  type='submit'
                  variant='contained'
                  styles={{ textTransform: 'none' }}
                />
              </Grid>
            </Grid>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AddRescheduleInterviewModal;
