export const getFileNameFromUrl = (url: string) => {
  try {
    const decodedUrl = decodeURIComponent(url);
    const urlObject = new URL(decodedUrl);
    return urlObject.pathname.split('/').pop() || '';
  } catch (error) {
    console.error('Error extracting filename from URL:', error);
    return '';
  }
};

export const isFileSizeValid = (value: any) => {
  if (value && value?.length > 0) {
    for (let i = 0; i < value.length; i++) {
      if (value[i].size > 5242880) {
        return false;
      }
    }
  }
  return true;
};
