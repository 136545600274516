import { getCareerPathList } from '../services/careerPath.service';
import { getProgramTracks } from '../services/programTrack.service';
import { getNavigatorList } from '../services/user.service';
export interface IfilterOptions {
  id: string;
  label: string;
  value: string;
}

export const statusOptions = [
  {
    id: '1',
    label: 'Prospective',
    value: 'prospective',
  },
  {
    id: '2',
    label: 'Pending Assignment',
    value: 'pending_assignment',
  },
  {
    id: '3',
    label: 'Pending Enrollment',
    value: 'pending_enrollment',
  },
  {
    id: '4',
    label: 'Enrollment Review',
    value: 'enrollment_review',
  },
  {
    id: '5',
    label: 'Enrolled',
    value: 'enrolled',
  },
  {
    id: '6',
    label: 'Completed',
    value: 'completed',
  },
  {
    id: '7',
    label: 'Drop Out',
    value: 'drop_out',
  },
  {
    id: '8',
    label: 'Suspended',
    value: 'suspended',
  },
];

export const genderOptions = [
  {
    id: '1',
    label: 'Male',
    value: 'Male',
  },
  {
    id: '2',
    label: 'Female',
    value: 'Female',
  },
  {
    id: '3',
    label: 'Other',
    value: 'Other',
  },
];

export const ethnicityOptions = [
  {
    id: '1',
    label: 'Native American or Alaskan Native',
    value: 'Native American or Alaskan Native',
  },
  {
    id: '2',
    label: 'African American / African / Afro-Caribbean',
    value: 'African American / African / Afro-Caribbean',
  },
  {
    id: '3',
    label: 'Asian / Pacific Islander or Native Hawaiian',
    value: 'Asian / Pacific Islander or Native Hawaiian',
  },
  {
    id: '4',
    label: 'Latino or Hispanic',
    value: 'Latino or Hispanic',
  },
  {
    id: '5',
    label: 'White (not Latino/a)',
    value: 'White (not Latino/a)',
  },
  {
    id: '6',
    label: 'Prefer not to disclose',
    value: 'Prefer not to disclose',
  },
  {
    id: '7',
    label: 'Multiracial',
    value: 'Multiracial',
  },
];

export const empStatusOptions = [
  {
    id: '1',
    label: 'Employed in-field by an employer who partners with your training program',
    value: 'Employed in-field by an employer who partners with your training program',
  },
  {
    id: '2',
    label: 'Employed in-field by an employer who doesn’t partner with your training program',
    value: 'Employed in-field by an employer who doesn’t partner with your training program',
  },
  {
    id: '3',
    label: 'Still seeking employment in-field',
    value: 'Still seeking employment in-field',
  },
  {
    id: '4',
    label: 'Not seeking employment in-field',
    value: 'Not seeking employment in-field',
  },
  {
    id: '5',
    label: 'Could not contact',
    value: 'Could not contact',
  },
];
// export const empStatusOptions = [
//   {
//     id: '1',
//     label: 'Employee Full - Time',
//     value: 'Employee Full - Time',
//   },
//   {
//     id: '2',
//     label: 'Employee Part - Time',
//     value: 'Employee Part - Time',
//   },
//   {
//     id: '3',
//     label: 'Unemployed',
//     value: 'Unemployed',
//   },
// ];

export const highestDLOptions = [
  { id: '1', label: 'No license', value: 'No license' },
  {
    id: '2',
    label: `Class D Learner’s permit (regular driver’s license permit)`,
    value: `Class D Learner’s permit (regular driver’s license permit)`,
  },
  {
    id: '3',
    label: `Class D license (regular driver’s license)`,
    value: `Class D license (regular driver’s license)`,
  },
  { id: '4', label: 'Class C permit', value: 'Class C permit' },
  { id: '5', label: 'Class C license', value: 'Class C license' },
  { id: '6', label: 'Class B permit', value: 'Class B permit' },
  { id: '7', label: 'Class B license', value: 'Class B license' },
  { id: '8', label: 'Class A permit', value: 'Class A permit' },
  { id: '9', label: 'Class A license', value: 'Class A license' },
];

export const educationOptions = [
  { id: '1', label: 'Less than 12th Grade', value: 'Less than 12th Grade' },
  { id: '2', label: 'High School Diploma or Equivalent', value: 'High School Diploma or Equivalent' },
  { id: '3', label: 'Post Secondary Education', value: 'Post Secondary Education' },
  { id: '4', label: 'Some College', value: 'Some College' },
  { id: '5', label: 'Associate’s Degree or higher', value: 'Associate’s Degree or higher' },
];

export const referralSourceOptions = [
  { id: '1', label: 'Administration for Children Services (ACS)', value: 'Administration for Children Services (ACS)' },
  { id: '2', label: 'Career Service', value: 'Career Service' },
  { id: '3', label: 'Jobs-Plus', value: 'Jobs-Plus' },
  { id: '4', label: 'NYCHA', value: 'NYCHA' },
  { id: '5', label: 'Office of Child Support Services (OCSS)', value: 'Office of Child Support Services (OCSS)' },
];

//eslint-disable-next-line
const totalHousehold =
  //eslint-disable-next-line
  'Total household income less than: $45k for 1 person; $61k for 2 people; $77k for 3 people; $93k for 4 people; $109k for 5 people; $125k for 6 people.';

export const publicAssistanceOptions = [
  { id: '1', label: 'Cash Assistance Receipt', value: 'Cash Assistance Receipt' },
  { id: '2', label: 'SNAP Receipt', value: 'SNAP Receipt' },
  { id: '3', label: 'Medicaid Receipt', value: 'Medicaid Receipt' },
  { id: '4', label: 'NYCHA Resident', value: 'NYCHA Resident' },
  { id: '5', label: 'Non-Custodial Parent', value: 'Non-Custodial Parent' },
  { id: '6', label: totalHousehold, value: totalHousehold },
  { id: '7', label: 'None of these options apply to me', value: 'None of these options apply to me' },
];

export const activeStatusOptions = [
  { id: '1', label: 'Active', value: 'active' },
  { id: '2', label: 'Inactive', value: 'inactive' },
];

export const employmentTypes = [
  { id: '1', label: 'Full-time', value: 'Full-time' },
  { id: '2', label: 'Part-time', value: 'Part-time' },
  { id: '3', label: 'Seasonal employment', value: 'Seasonal employment' },
  { id: '4', label: 'Earn and Learn', value: 'Earn and Learn' },
];

const getInterestedInOptions = async () => {
  const careerPathList = await getCareerPathList();
  const options: IfilterOptions[] = [];
  for (const careerPath of careerPathList) {
    options.push({
      id: careerPath?.id || '',
      label: careerPath?.name || '',
      value: careerPath?.id || '',
    });
  }
  return options;
};
const getProgramTrackOptions = async () => {
  const programTrackList = await getProgramTracks();
  const options: IfilterOptions[] = [];
  for (const programTrack of programTrackList) {
    options.push({
      id: programTrack?.id || '',
      label: programTrack?.name || '',
      value: programTrack?.id || '',
    });
  }
  return options;
};
const getNavigatorOptions = async () => {
  const navigatorList = await getNavigatorList();
  const options: IfilterOptions[] = [];
  for (const navigator of navigatorList) {
    options.push({
      id: navigator?.id || '',
      label: navigator?.firstName + ' ' + navigator?.lastName,
      value: navigator?.id || '',
    });
  }
  return options;
};

export const ClientEmploymentOptions = [
  {
    id: '1',
    label: 'Pending Employment Liaison',
    value: 'Pending Employment Liaison',
  },
  {
    id: '2',
    label: 'Pending Employment Intake',
    value: 'Pending Employment Intake',
  },
  {
    id: '3',
    label: 'Enrolled in Workshop',
    value: 'Enrolled in Workshop',
  },
  {
    id: '4',
    label: 'Job Ready',
    value: 'Job Ready',
  },
  {
    id: '5',
    label: 'Interview scheduled',
    value: 'Interview scheduled',
  },
  {
    id: '6',
    label: 'Hired',
    value: 'Hired',
  },
];
export const getFilters = async (isClientList: boolean) => {
  let filters: any[] = [];
  if (isClientList) {
    filters = [
      {
        id: 'status',
        label: 'Status',
        options: statusOptions,
      },
      {
        id: 'gender',
        label: 'Gender',
        options: genderOptions,
      },
      {
        id: 'ethnic',
        label: 'Race/Ethnic Identity',
        options: ethnicityOptions,
      },
      {
        id: 'highestDL',
        label: `Highest Driver's license obtained`,
        options: highestDLOptions,
      },
      {
        id: 'educationalBackground',
        label: 'Educational Background',
        options: educationOptions,
      },
      {
        id: 'referralSource',
        label: 'Referral Source',
        options: referralSourceOptions,
      },
      {
        id: 'employmentStatus',
        label: 'Employment Status',
        options: empStatusOptions,
      },
      {
        id: 'employmentType',
        label: 'Employment Type',
        options: employmentTypes,
      },
      {
        id: 'publicAssistance',
        label: 'Household Supports and Income',
        options: publicAssistanceOptions,
      },
      {
        id: 'activeStatus',
        label: 'Active Status',
        options: activeStatusOptions,
      },
      {
        id: 'interestedIn',
        label: 'Interested In',
        options: await getInterestedInOptions(),
      },
      {
        id: 'programTrack',
        label: 'Program Track',
        options: await getProgramTrackOptions(),
      },
      {
        id: 'assignedTo',
        label: 'Navigator',
        options: await getNavigatorOptions(),
      },
    ];
  } else {
    filters = [
      {
        id: 'employmentModuleStatus',
        label: 'Employment Module Status',
        options: ClientEmploymentOptions,
      },
      {
        id: 'employmentStatus',
        label: 'Employment Status',
        options: empStatusOptions,
      },
      {
        id: 'employmentType',
        label: 'Employment Type',
        options: employmentTypes,
      },
    ];
  }
  return filters;
};
