import React, { useEffect, useState } from 'react';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { COLORS } from '../../utils/colors';
import { getClientsCompletedProgramTracks } from '../../services/programTrackClientMap.service';
import { convertTimeStampToDate } from '../../utils/dateTime';
import { Loader } from '../Loader/Loader';
import { useNavigate } from 'react-router-dom';

interface ClientCompletedProgramTracksProps {
  clientId: string;
}

const ClientCompletedProgramTracks = (props: ClientCompletedProgramTracksProps) => {
  const navigate = useNavigate();
  const { clientId } = props;
  const [completedProgramTracks, setCompletedProgramTracks] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(
    () => {
      (async () => {
        try {
          setLoading(true);
          const data: any = await getClientsCompletedProgramTracks(clientId);
          setCompletedProgramTracks(data);
          setLoading(false);
        } catch (error) {
          //catch err
        }
      })();
    },
    // eslint-disable-next-line
    []
  );
  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <Grid container>
        <Typography variant='h6' sx={{ mb: 2 }} gutterBottom>
          Completed Program Tracks
        </Typography>
        {completedProgramTracks.length > 0 ? (
          <Grid item xs={12} lg={12}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={styles.columnTitle}>Program Track</TableCell>
                    <TableCell sx={styles.columnTitle}>Career Path</TableCell>
                    <TableCell sx={styles.columnTitle}>Curriculum </TableCell>
                    <TableCell sx={styles.columnTitle}>Training Vendor</TableCell>
                    <TableCell sx={styles.columnTitle}>Start Date</TableCell>
                    <TableCell sx={styles.columnTitle}>End Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {completedProgramTracks.map((row: any, index: any) => (
                    <TableRow
                      onClick={() => {
                        navigate('../program-track-info', { state: { programTrackId: row.id } });
                      }}
                      key={index}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        '&:hover': { cursor: 'pointer', backgroundColor: COLORS.card.cardBg },
                      }}>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.careerPathName}</TableCell>
                      <TableCell>{row.curriculumName}</TableCell>
                      <TableCell>{row.trainerName}</TableCell>
                      <TableCell component='th' scope='row'>
                        {convertTimeStampToDate(row.startTime).tz('America/New_York').format('MM/DD/YYYY, hh:mm A')}
                      </TableCell>
                      <TableCell component='th' scope='row'>
                        {convertTimeStampToDate(row.endTime).tz('America/New_York').format('MM/DD/YYYY, hh:mm A')}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};

export default ClientCompletedProgramTracks;

const styles = {
  columnTitle: {
    color: COLORS.palette.black,
    fontWeight: 'bold',
  },
};
