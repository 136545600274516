import { Grid, Paper, Typography } from '@mui/material';
import { IFilterItem } from '.';

interface IFilterList {
  filterList: IFilterItem[];
  activeIds: any[];
  handleFilterClick: any;
}

export default function FilterList(props: IFilterList) {
  return (
    <Grid container spacing={1} alignItems='center' sx={{ mb: 4 }} columns={{ xs: 1, lg: 12 }}>
      {props.filterList.map((item) => {
        const isActive = props.activeIds.includes(item.id);
        const lg = 12 / props.filterList.length < 1 ? 2 : 12 / props.filterList.length;
        return (
          <Grid item xs={2} lg={lg} key={item.id}>
            <Paper
              elevation={0}
              sx={{
                py: 1,
                px: 1,
                background: item.bgColor,
                borderRadius: '8px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                cursor: 'pointer',
                border: isActive ? '2px solid black' : 'none',
              }}
              onClick={() => {
                props.handleFilterClick(item.id);
              }}>
              <Typography
                variant='button'
                sx={{
                  textTransform: 'uppercase',
                  fontSize: '10px',
                  color: isActive ? '#000' : item.textColor,
                }}>
                {item.label.split('_').join(' ')}
              </Typography>
              <item.icon sx={{ color: item.textColor }} />
            </Paper>
          </Grid>
        );
      })}
    </Grid>
  );
}
