import { Box, Button, Chip, Divider, Grid, Stack, Typography } from '@mui/material';
import { COLORS } from '../../../utils/colors';
import { DashboardProgramCard } from '../../../components/DashboardProgramCard/DashboardProgramCard';
import { UserModel } from '../../../store/user/userModel';
import { useAppSelector } from '../../../store/store';
import ProspectiveClientsDashboardCard from '../../../components/ProspectiveClientsDashboardCard';
import ClientsPendingAssignmentDashboardCard from '../../../components/ClientsPendingAssignmentDashboardCard';
import ClientsPendingEnrollmentDashboardCard from '../../../components/ClientsPendingEnrollmentDashboardCard';
import ClientsPending154ELetterDashboardCard from '../../../components/ClientsPending154ELetterDashboardCard';
import { shouldShowButton } from './dashboardAccessControl';
import AddInfoSessionWidget from '../../../components/Widgets/AddInfoSession';
import AddInviteProspectiveClientWidget from '../../../components/Widgets/AddInviteProspectiveClient';
import CreateProgramTrackWidget from '../../../components/Widgets/CreateProgramTrack';
import CreateNewCurriculumWidget from '../../../components/Widgets/CreateNewCurriculum';
import CreateCareerPathWidget from '../../../components/Widgets/CreateCareerPath';
import CreateClientButton from '../../../components/CreateClientButton/CreateClientButton';
import ViewInfoSessionButton from '../../../components/ViewInfoSessionButton/ViewInfoSessionButton';
import AddWorkshopButton from '../../../components/Widgets/AddWorkshopButton';
import ViewWorkshopsButton from '../../../components/Widgets/ViewWorkshopsButton';
import ClientsOnLiaisonDashBoardCard from '../../../components/ClientsOnLiaisonDashBoardCard';
import { syncClientDataToClientList } from '../../../services/syncData.service';

const styles = {
  gridContainer: {
    marginX: '15px',
    paddingX: '10px',
    height: '376px',
    overflow: 'scroll',
    background: COLORS.palette.white,
    border: `1px solid ${COLORS.palette.borderColor}`,
    borderRadius: '8px',
  },
};

const Dashboard = () => {
  const user: UserModel = useAppSelector((state: any) => state.user.user);
  const userRole: string = user ? user.role : '';
  const showQuickLinksDivider: boolean =
    shouldShowButton('createClient', userRole) ||
    shouldShowButton('createCareerPath', userRole) ||
    shouldShowButton('createInfoSession', userRole) ||
    shouldShowButton('createNewCurriculum', userRole) ||
    shouldShowButton('createProgramTrack', userRole) ||
    shouldShowButton('inviteProspectiveClient', userRole) ||
    shouldShowButton('viewInfoSession', userRole);

  const showClientDivider: boolean =
    shouldShowButton('createClient', userRole) || shouldShowButton('inviteProspectiveClient', userRole);

  const showInfoSessionDivider: boolean =
    shouldShowButton('createInfoSession', userRole) || shouldShowButton('viewInfoSession', userRole);

  const showWorkshopDivider: boolean =
    shouldShowButton('createWorkshop', userRole) || shouldShowButton('viewWorkshops', userRole);

  const showCourseDivider: boolean =
    shouldShowButton('createCareerPath', userRole) ||
    shouldShowButton('createNewCurriculum', userRole) ||
    shouldShowButton('createProgramTrack', userRole);

  const handleSyncClientData = async () => {
    await syncClientDataToClientList();
  };

  return (
    <>
      <Grid container>
        <Grid item lg={8} xs={12} sx={{ pt: 5 }}>
          <Grid container spacing={2}>
            <ProspectiveClientsDashboardCard userRole={userRole} adminType={user?.adminType || ''} />
            <ClientsPendingAssignmentDashboardCard userRole={userRole} adminType={user?.adminType || ''} />
            <ClientsPendingEnrollmentDashboardCard userRole={userRole} adminType={user?.adminType || ''} />
            <ClientsPending154ELetterDashboardCard userRole={userRole} />
            <ClientsOnLiaisonDashBoardCard userRole={userRole} adminType={user?.adminType || ''} />
            <ClientsOnLiaisonDashBoardCard
              userRole={userRole}
              adminType={user?.adminType || ''}
              isCompletedClients={true}
            />
            <ClientsOnLiaisonDashBoardCard
              userRole={userRole}
              adminType={user?.adminType || ''}
              isJRTCompleted={true}
            />
          </Grid>
        </Grid>
        <Grid item lg={4} xs={12} sx={{ pt: 5 }}>
          <Grid container sx={styles.gridContainer}>
            <Grid item lg={12} xs={12}>
              <Typography variant='h5' sx={{ paddingTop: 2 }}>
                Program Tracks
              </Typography>
              <Typography sx={{ fontSize: '12px' }} color='text.secondary'>
                List of Active Program Tracks
              </Typography>
              <Stack spacing={3} sx={{ marginBottom: '30px', marginTop: '10px' }}>
                <DashboardProgramCard user={user} />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Quick Links Divider */}
      {showQuickLinksDivider ? (
        <Box pt={4}>
          <Divider>
            <Chip label='Quick Links' sx={{ fontWeight: 'bold' }} />
          </Divider>
        </Box>
      ) : null}

      {/* Client Divider */}
      {showClientDivider ? (
        <Divider textAlign='left' sx={{ pt: 4, pb: 1 }}>
          <Typography sx={{ fontWeight: 'bold' }}>Clients</Typography>
        </Divider>
      ) : null}

      <Grid container mb={2} spacing={1}>
        {shouldShowButton('createClient', userRole) ? (
          <Grid item lg={4} xs={12}>
            <CreateClientButton />
          </Grid>
        ) : null}

        {shouldShowButton('inviteProspectiveClient', userRole) ? (
          <Grid item lg={4} xs={12}>
            <AddInviteProspectiveClientWidget />
          </Grid>
        ) : null}
      </Grid>

      {/* Info Session Divider */}
      {showInfoSessionDivider ? (
        <Divider textAlign='left' sx={{ pt: 4, pb: 1 }}>
          <Typography sx={{ fontWeight: 'bold' }}>Info Session</Typography>
        </Divider>
      ) : null}

      <Grid container mb={2} spacing={1}>
        {shouldShowButton('createInfoSession', userRole) ? (
          <Grid item lg={4} xs={12}>
            <AddInfoSessionWidget />
          </Grid>
        ) : null}

        {shouldShowButton('viewInfoSession', userRole) ? (
          <Grid item lg={4} xs={12}>
            <ViewInfoSessionButton />
          </Grid>
        ) : null}
      </Grid>

      {/* Workshop Divider */}
      {showWorkshopDivider ? (
        <Divider textAlign='left' sx={{ pt: 4, pb: 1 }}>
          <Typography sx={{ fontWeight: 'bold' }}>Workshop</Typography>
        </Divider>
      ) : null}

      <Grid container mb={2} spacing={1}>
        {shouldShowButton('createWorkshop', userRole) ? (
          <Grid item lg={4} xs={12}>
            <AddWorkshopButton />
          </Grid>
        ) : null}

        {shouldShowButton('viewWorkshops', userRole) ? (
          <Grid item lg={4} xs={12}>
            <ViewWorkshopsButton />
          </Grid>
        ) : null}
      </Grid>

      {/* Course Divider */}
      {showCourseDivider ? (
        <Divider textAlign='left' sx={{ pt: 4, pb: 1 }}>
          <Typography sx={{ fontWeight: 'bold' }}>Course</Typography>
        </Divider>
      ) : null}

      <Grid container mb={2} spacing={1}>
        {shouldShowButton('createProgramTrack', userRole) ? (
          <Grid item lg={4} xs={12}>
            <CreateProgramTrackWidget />
          </Grid>
        ) : null}

        {shouldShowButton('createNewCurriculum', userRole) ? (
          <Grid item lg={4} xs={12}>
            <CreateNewCurriculumWidget />
          </Grid>
        ) : null}

        {shouldShowButton('createCareerPath', userRole) ? (
          <Grid item lg={4} xs={12}>
            <CreateCareerPathWidget />
          </Grid>
        ) : null}
      </Grid>
      <Button onClick={handleSyncClientData}>Sync Data</Button>
    </>
  );
};

export default Dashboard;
