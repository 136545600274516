import React, { useState } from 'react';
import { Box, List, ListItem, ListItemText, Checkbox } from '@mui/material';
import dayjs from 'dayjs';
import { infoSessionTypes } from '../../utils/infoSessionTypeEnums';
import { InfoSessionEditModel } from '../../store/infoSession/infoSessionModel';

interface InfoSessionBasicInfoProps {
  infoSessionData: InfoSessionEditModel;
  onSelectChange: Function;
  selectedInfoSessionIds: Array<string>;
}

const InfoSessionBasicInfo = (props: InfoSessionBasicInfoProps) => {
  const { infoSessionData, onSelectChange, selectedInfoSessionIds } = props;
  const [isChecked, setIsChecked] = useState(selectedInfoSessionIds?.includes(infoSessionData.id));

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    onSelectChange(infoSessionData.id, !isChecked);
  };

  const renderListItem = (primary: string, secondary: string) => (
    <ListItem disablePadding key={primary}>
      <ListItemText
        primary={primary}
        secondary={secondary}
        primaryTypographyProps={styles.listItemPrimaryText}
        sx={styles.listItemContainer}
      />
    </ListItem>
  );

  const infoSessionDetail = [
    {
      primary: 'Date & Time: ',
      secondary: dayjs(infoSessionData.dateTime).tz('America/New_York').format('MMMM DD, YYYY, HH:mm'),
    },
    { primary: 'Program: ', secondary: infoSessionData.title },
  ];
  if (infoSessionData.sessionType === infoSessionTypes.IN_PERSON) {
    infoSessionDetail.push({ primary: 'Location: ', secondary: infoSessionData.location });
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Checkbox
        checked={isChecked}
        onChange={handleCheckboxChange}
        color='primary'
        sx={{ flexDirection: 'column', justifyContent: 'flex-start', mr: 1 }}
      />
      <List>{infoSessionDetail.map((item) => renderListItem(item.primary, item.secondary))}</List>
    </Box>
  );
};

const styles = {
  listItemContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  listItemPrimaryText: {
    fontWeight: 'bold',
    paddingRight: 1,
  },
};

export default InfoSessionBasicInfo;
