import { ListAltOutlined, OtherHousesOutlined } from '@mui/icons-material';

export const ProspectiveClientSidebarMenuItems = [
  {
    path: '/client/home',
    name: 'Home',
    icon: <OtherHousesOutlined />,
  },
  {
    path: '/client/career-paths',
    name: 'Career Paths',
    icon: <ListAltOutlined />,
  },
];
