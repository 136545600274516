import React, { useState } from 'react';
import { Box, IconButton, TextField, Typography } from '@mui/material';
import { COLORS } from '../../utils/colors';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import { sendTextMessage } from '../../services/client.service';
import ClientListToSendGroupMsg from '../ClientListToSendGroupMsg/ClientListToSendGroupMsg';

interface ISendGroupMessageToClientsProps {
  clientIds: string[];
}

const SendGroupMessageToClients = ({ clientIds }: ISendGroupMessageToClientsProps) => {
  const [message, setMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedClients, setSelectedClients] = useState<string[]>([]);

  const handleClientSelect = (clientId: string) => {
    if (selectedClients.includes(clientId)) {
      setSelectedClients(selectedClients.filter((id: string) => id !== clientId));
    } else {
      setSelectedClients([...selectedClients, clientId]);
    }
  };

  const handleMessageChange = (event: any) => {
    setMessage(event.target.value);
  };

  const handleSendMessage = async () => {
    try {
      setLoading(true);
      await sendTextMessage(selectedClients, message);
      setMessage('');
      setLoading(false);
    } catch (err) {
      //catch error
    }
  };
  return (
    <Box
      sx={{
        background: COLORS.card.cardBg,
        borderRadius: '10px',
        padding: '20px',
        width: '100%',
      }}>
      <Typography variant='h6' sx={{ mb: 1 }}>
        Send a Group SMS Text Message.
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          fullWidth
          size='small'
          disabled={clientIds.length === 0}
          variant='outlined'
          label='Type your message'
          value={message}
          onChange={handleMessageChange}
          sx={{ marginRight: '10px' }}
        />
        <IconButton
          color='primary'
          onClick={handleSendMessage}
          size='small'
          disabled={loading || message.length === 0 || selectedClients.length === 0}
          sx={{
            backgroundColor: COLORS.theme.primaryColor,
            color: COLORS.palette.white,
            '&:hover': {
              backgroundColor: COLORS.theme.lightPrimaryColor,
            },
          }}>
          <SendRoundedIcon />
        </IconButton>
      </Box>
      <Box sx={{ mt: 2 }}>
        <ClientListToSendGroupMsg
          clientIds={clientIds}
          handleClientSelect={handleClientSelect}
          selectedClients={selectedClients}
          setSelectedClients={setSelectedClients}
        />
      </Box>
    </Box>
  );
};

export default SendGroupMessageToClients;
