export interface InfoSessionModel {
  title: string;
  location: string;
  description: string;
  dateTime: number | string;
  createdBy: string;
  hra: string;
  sessionType: string;
  meetingLink?: string;
  customUrlName?: string;
  repeatWeekly: boolean;
  repeatWeeks?: number;
  numClients: number;
  isAttendanceMarked?: boolean;
}

export interface InfoSessionReduxModel {
  infoSessionList: InfoSessionModel[];
}

export interface InfoSessionEditModel {
  id: string;
  title: string;
  location: string;
  description: string;
  dateTime: number | string;
  createdBy: string;
  hra: string;
  sessionType: string;
  meetingLink?: string;
  repeatWeekly: boolean;
  repeatWeeks?: number;
  numClients: number;
  isAttendanceMarked?: boolean;
  createdAt?: string;
  customUrlName: string;
}

export const infoSessionEmpty = {
  id: '',
  title: '',
  location: '',
  description: '',
  dateTime: 0,
  createdBy: '',
  hra: '',
  sessionType: '',
  meetingLink: '',
  customUrlName: '',
  repeatWeekly: false,
  isAttendanceMarked: false,
  repeatWeeks: 0,
  numClients: 0,
};

export interface IAddPCToAttendanceRoster {
  ids: string[];
  infoSessionId: string;
}
