import React from 'react';
import { Container, Grid, Link, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { COLORS } from '../../../utils/colors';
import { isIOS } from '../../../utils/platform';
import CircleIcon from '@mui/icons-material/Circle';
import { authorizedOrganizations, termsConditions } from '../../../utils/common';
import { useLocation } from 'react-router-dom';

const ClientAgreement = () => {
  const { applicantName } = useLocation().state;
  const handleBackButtonClick = () => {
    window.history.back();
  };

  return (
    <Grid container spacing={0} direction='column' alignItems='center' justifyContent='center' sx={styles.root}>
      <Grid item xs={12}>
        <img alt='Logo' src={'/assets/logo.png'} style={isIOS() ? { width: 50 } : { width: 100 }} />
      </Grid>
      <Grid item xs={12} sx={styles.centeredStyle} my={4}>
        <Grid container direction='row' mb={4} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant='h5'>User Agreement - PINCC</Typography>
        </Grid>
        <Container>
          <Typography mb={1} variant='body1'>
            PINCC Applicant Authorization to Request and/or Release Information
          </Typography>
          <Typography variant='body1'>{`Applicant Name: ${[applicantName]}`}</Typography>
          <Typography my={1} variant='body1'>
            I hereby authorize PINCC and/or its HRA Program Service Providers to request and release information
            relevant to my participation in the PINCC Program and/or follow-on employment. This authorization may
            pertain to one or more of the following organizations:
          </Typography>
          <List>
            {authorizedOrganizations.map((organization, index) => (
              <ListItem disablePadding key={index}>
                <ListItemIcon>
                  <CircleIcon fontSize='small' sx={{ color: '#000000', fontSize: '10px' }} />
                </ListItemIcon>
                <ListItemText primary={organization} />
              </ListItem>
            ))}
          </List>
          <Typography my={1}>I understand that:</Typography>
          <List>
            {termsConditions.map((organization, index) => (
              <ListItem disablePadding key={index}>
                <ListItemIcon>
                  <CircleIcon fontSize='small' sx={{ color: '#000000', fontSize: '10px' }} />
                </ListItemIcon>
                <ListItemText primary={organization} />
              </ListItem>
            ))}
          </List>
          <Typography my={1}>Acknowledgements:</Typography>
          <Typography my={1}>
            I will collaborate with my PINCC Career Navigator to identify my employment goals and plan activities to
            facilitate my re-entry into the workforce. I agree to fulfill all activities and responsibilities outlined
            in my employment goal. If I encounter any issues with my activity, I will promptly notify my Career
            Navigator or PINCC HRA Liaison.
          </Typography>
          <Typography my={1}>
            By agreeing, I acknowledge that my opportunities and obligations as a participant of the PINCC Program have
            been explained to me.
          </Typography>
          <Typography my={1}>
            As the PINCC Career Navigator, I will assist the PINCC Participant identified above in planning the
            necessary activities (to the extent that services are available). I will monitor progress and attendance in
            assigned activities, provide regular feedback, and offer employment counseling when necessary.
          </Typography>
          <Grid item xs={12} mt={4}>
            <Link onClick={handleBackButtonClick} sx={styles.backBtn}>
              Back to Previous Page
            </Link>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
};

export default ClientAgreement;

const styles = {
  root: {
    minHeight: '100vh',
    backgroundColor: COLORS.theme.primaryColor,
  },
  backBtn: {
    display: 'flex',
    justifyContent: 'center',
    color: COLORS.theme.primaryColor,
    textDecoration: 'none',
    fontSize: '16px',
    lineHeight: '19px',
    cursor: 'pointer',
  },
  centeredStyle: {
    width: '80%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
    borderRadius: '14px',
    '@media (max-width: 480px)': {
      width: '90%',
      height: '90%',
      padding: '20px',
      margin: '10px',
    },
  },
};
