import React, { useState, useEffect } from 'react';
import { useAppSelector } from '../../../store/store';
import { UserModel } from '../../../store/user/userModel';
import { Box, Button, Divider, Grid, Link, Typography } from '@mui/material';
import { getInfoSessionById, getMultipleInfoSessionByIds } from '../../../services/infoSession.service';
import { ClientInfoSession } from '../../../components/ClientInfoSession/ClientInfoSession';
import { Loader } from '../../../components/Loader/Loader';
import { ClientStatus } from '../../../utils/clientStatus';
import { getClientMapDataByClientId } from '../../../services/programTrackClientMap.service';
import { programTrackInfoById } from '../../../services/programTrack.service';
import { getCareerPathInfoById } from '../../../services/careerPath.service';
import { ClientProgramTrack } from '../../../components/ClientProgramTrack/ClientProgramTrack';
import { COLORS } from '../../../utils/colors';
import { getWorkshopById } from '../../../services/workshop.service';
import { IClientJRTComplete, IWorkshopInfo, emptyWorkshopInfo } from '../../../store/employmentModule/workshopModal';
import WorkshopBasicInfo from '../../../components/WorkshopBasicInfo/WorkshopBasicInfo';
import { getJobTitleById } from '../../../services/employmentModule.service';
import { useNavigate } from 'react-router-dom';
import WorkshopAttachmentView from '../../../components/WorkshopAttachmentView/WorkshopAttachmentView';
import { LoadingButton } from '../../../components/LoadingButton/LoadingButton';
import { toast } from 'react-toastify';
import { markClientsWorkshopCompleted } from '../../../services/client.service';
import dayjs from 'dayjs';

export default function ClientHome() {
  const navigate = useNavigate();
  const user: UserModel = useAppSelector((state: any) => state.user.user);
  const [infoSessionInfo, setInfoSessionInfo] = useState<any>();
  const [multipleInfoSessions, setMultipleInfoSessions] = useState<any>();

  const [programTrackInfo, setProgramTrackInfo] = useState<any>();
  const [clientMapData, setClientMapData] = useState<any>();
  const [careerPathInfo, setCareerPathInfo] = useState<any>();

  const [workshop, setWorkshop] = useState<IWorkshopInfo>(emptyWorkshopInfo);
  const [loading, setLoading] = useState<boolean>(false);

  const durationInSeconds = programTrackInfo?.endTime - programTrackInfo?.startTime;
  const durationInDays = Math.floor(durationInSeconds / (24 * 60 * 60));
  const rollingLastDay = programTrackInfo?.milestones[programTrackInfo.milestones.length - 1].days;
  const [jobTitles, setJobTitles] = useState<{ id: string; title: string }[]>([]);

  useEffect(() => {
    const fetchJobTitles = () => {
      if (user?.scheduledJobInterview) {
        const unsubscribes = user.scheduledJobInterview.map((item: any) => {
          const jobId = item.jobId;
          const unsubscribe = getJobTitleById(jobId, (title: any) => {
            setJobTitles((prevTitles) => {
              const updatedTitles = prevTitles.filter((titleObj) => titleObj.id !== jobId);
              return [
                ...updatedTitles,
                {
                  title,
                  id: jobId,
                },
              ];
            });
          });
          return unsubscribe;
        });

        // Cleanup function
        return () => {
          unsubscribes.forEach((unsubscribe: any) => unsubscribe());
        };
      }
    };

    fetchJobTitles();
  }, [user?.scheduledJobInterview]);

  useEffect(() => {
    if (user) {
      (async () => {
        //program track info data
        const clientMapDataInfo: any = await getClientMapDataByClientId(user?.id || '');
        if (clientMapDataInfo?.length > 0) {
          const programTrack: any = await programTrackInfoById(clientMapDataInfo[0]?.programTrackId);
          setProgramTrackInfo(programTrack);
          const CareerPathData: any = await getCareerPathInfoById(programTrack?.careerPathId);
          setCareerPathInfo(CareerPathData);
          setClientMapData(
            clientMapDataInfo.find((i: any) => i.programTrackId === clientMapDataInfo[0]?.programTrackId)
          );
        }
      })();
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (user) {
      (async () => {
        if (user.infoSessionId) {
          const unsubscribe = await getInfoSessionById(user.infoSessionId, (data: any) => {
            setInfoSessionInfo(data);
          });
          if (unsubscribe) {
            return () => {
              unsubscribe();
            };
          }
        } else if (user.infoSessionIds) {
          const info: any = await getMultipleInfoSessionByIds(user.infoSessionIds);
          setMultipleInfoSessions(info);
        }
      })();
    }
    // eslint-disable-next-line
  }, []);

  const getWorkshopInfo = async () => {
    try {
      if (user.workshopId) {
        const unsubscribe = await getWorkshopById(user.workshopId, (data: any) => {
          setWorkshop(data);
        });
        return () => {
          unsubscribe();
        };
      }
    } catch (err: any) {
      //error handling
    }
  };

  useEffect(() => {
    getWorkshopInfo();
    // eslint-disable-next-line
  }, []);

  const handleAttendance = async () => {
    if (user.id && user.workshopId) {
      setLoading(true);
      const payload: IClientJRTComplete = {
        id: user.id || '',
        workshopId: user.workshopId || '',
        markedAt: dayjs().toISOString(),
      };
      const response: any = await markClientsWorkshopCompleted(payload);
      if (response?.success) {
        toast.success(response.message);
      } else {
        toast.error('Something went wrong');
      }
      setLoading(false);
    }
  };

  const isWorkshopAttendanceMarked =
    user?.completedWorkshops?.some((item: any) => item.workshopId === user?.workshopId) || false;

  const renderWorkshopDetails = () => (
    <Grid item lg={4} xs={12} p={2} sx={{ background: COLORS.card.cardBg }} borderRadius={'10px'}>
      <Typography variant='h6'>Workshop Details</Typography>
      <WorkshopBasicInfo workshop={workshop} />
      <Divider>Resources</Divider>
      <WorkshopAttachmentView workshop={workshop} />
      <Divider>Attendance</Divider>
      <LoadingButton
        onClick={handleAttendance}
        label='Complete Workshop'
        loading={loading}
        disabled={isWorkshopAttendanceMarked}
        size='medium'
        variant='contained'
        styles={{ textTransform: 'none', width: '100%', marginTop: '20px' }}
      />
    </Grid>
  );
  const renderProgramTrackDetails = () => (
    <Grid item xs={12} lg={4} p={2} sx={{ background: COLORS.card.cardBg }} borderRadius={'10px'}>
      <Typography mb={1} variant='h6'>
        Program Track Details
      </Typography>
      <ClientProgramTrack
        milestones={programTrackInfo.milestones || []}
        completedMilestones={clientMapData ? clientMapData.completedMilestones : []}
        durationInDays={durationInDays ? durationInDays : rollingLastDay}
        careerPathInfo={careerPathInfo}
        programTrackName={programTrackInfo.name}
        programTrackType={programTrackInfo.type}
      />
    </Grid>
  );

  const handleTitleClick = (jobId: string) => {
    navigate('../job-info', {
      state: { jobId },
    });
  };

  const renderJobDetails = () => {
    return (
      <Grid item xs={12} lg={4} p={2} sx={{ background: COLORS.card.cardBg }} borderRadius={'10px'}>
        <Typography variant='h6'>Interview Scheduled in following Jobs</Typography>
        {jobTitles.map((item, index) => (
          <Button
            key={index}
            onClick={() => handleTitleClick(item.id)}
            variant='text'
            sx={{ display: 'flex', borderRadius: '20px' }}>
            <Typography mb={1} pl={1} variant='body1'>
              {item.title}
            </Typography>
          </Button>
        ))}
      </Grid>
    );
  };

  const renderHomeView = () => {
    if (user.status === ClientStatus.PROSPECTIVE) {
      if (user.infoSessionId) {
        return <ClientInfoSession data={infoSessionInfo} />;
      } else if (user.infoSessionIds) {
        <Grid container m={4}>
          {multipleInfoSessions?.map((item: any, index: number) => {
            <Grid key={index} item xs={12} lg={5}>
              <ClientInfoSession data={item} />
            </Grid>;
          })}
        </Grid>;
      } else {
        return (
          <Box py={5} my={5} justifyContent={'center'}>
            <Typography variant='h6'>
              No info session scheduled. &nbsp;
              <Link href='/client/career-paths'>Click here</Link>
              &nbsp; to view the available career paths and indicate you interest.
            </Typography>
          </Box>
        );
      }
    }
    if (user.status === ClientStatus.PENDING_ASSIGNMENT && !user.workshopId) {
      return (
        <Box py={5} my={5} justifyContent={'center'}>
          <Typography variant='h6'>Yet to be Assigned a Navigator.</Typography>
        </Box>
      );
    }
    if (user.status === ClientStatus.PENDING_ASSIGNMENT && user.workshopId) {
      return (
        <Grid container m={2} columns={12} justifyContent='space-between'>
          {renderWorkshopDetails()}
          {user.scheduledJobInterview && renderJobDetails()}
        </Grid>
      );
    }
    if (user.status === ClientStatus.ENROLLMENT_REVIEW && user.workshopId) {
      return (
        <Grid container m={2} columns={12} justifyContent='space-between'>
          {renderWorkshopDetails()}
          {user.scheduledJobInterview && renderJobDetails()}
        </Grid>
      );
    }
    if (user.status === ClientStatus.ENROLLMENT_REVIEW && !user.workshopId) {
      return (
        <Box py={5} my={5} justifyContent={'center'}>
          <Typography variant='h6'>Yet to be Enrolled in a Program Track.</Typography>
        </Box>
      );
    }
    if (user.status === ClientStatus.ENROLLED && programTrackInfo) {
      return (
        <Grid container my={2} columns={13} justifyContent='space-between'>
          {renderProgramTrackDetails()}
          {user.scheduledJobInterview && renderJobDetails()}
          {user.workshopId && renderWorkshopDetails()}
        </Grid>
      );
    } else if (user.status !== ClientStatus.PROSPECTIVE && !programTrackInfo) {
      return (
        <Box py={5} my={5} justifyContent={'center'}>
          <Typography variant='h6'>Not enrolled to any program tracks yet.</Typography>
        </Box>
      );
    } else {
      return <Loader />;
    }
  };

  if (!user) {
    return <Loader />;
  }

  return (
    <Box p={5}>
      <Typography variant='h4'>Home</Typography>
      {renderHomeView()}
    </Box>
  );
}
