import { ChatBubbleOutlineOutlined, ListAltOutlined, OtherHousesOutlined } from '@mui/icons-material';

export const ClientSidebarMenuItems = [
  {
    path: '/client/home',
    name: 'Home',
    icon: <OtherHousesOutlined />,
  },
  {
    path: '/client/documents',
    name: 'Documents',
    icon: <ListAltOutlined />,
  },
  {
    path: '/client/messaging',
    name: 'Messaging',
    icon: <ChatBubbleOutlineOutlined />,
  },
];
