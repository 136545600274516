import React, { useState, useEffect } from 'react';
import { PersonAdd } from '@mui/icons-material';
import { Avatar, Box, Button, Chip, Grid, IconButton, List, ListItem, Typography } from '@mui/material';
import { ClientListStatuses, getStatusColors } from './utils';
import { COLORS } from '../../../utils/colors';
import { useNavigate } from 'react-router-dom';
import {
  ICLientMetaInfo,
  ICommonClient,
  deleteClient,
  unenrollClient,
  searchClients,
  listClientByQuery,
  listClientByFiltersApplied,
} from '../../../services/client.service';
import { toast } from 'react-toastify';
import AddProspectiveClientModal from '../../../components/Modals/AddProspectiveClient/AddProspectiveClient';
import CustomTable from '../../../components/CustomTable';
import { shouldShowButton } from '../dashboard/dashboardAccessControl';
import { UserModel } from '../../../store/user/userModel';
import { useAppSelector } from '../../../store/store';
import { ClientStatus } from '../../../utils/clientStatus';
import { UserRolesEnum } from '../../../utils/rolesEnum';
import DeleteIcon from '@mui/icons-material/Delete';
import GenericDialog from '../../../components/Dialog/genericDialog/genericDialog';
import { programTrackTypes } from '../../../utils/programTrackEnums';
import dayjs from 'dayjs';
import { fetchInfoSessionList } from '../../../services/infoSession.service';
import { getPtcmDataInReviewByClientId } from '../../../services/programTrackClientMap.service';
import { InfoSessionEditModel } from '../../../store/infoSession/infoSessionModel';
import { EMAIL_REGEX, MOBILE_REGEX } from '../../../utils/regex';
import FilterModal from '../../../components/Modals/FilterModal/FilterModal';

//render column header
const renderColHeader = (headerName: any) => {
  return (
    <Grid container sx={{ justifyContent: 'center', alignItems: 'center', width: 300 }} justifyContent={'center'}>
      <Typography variant='button' textTransform={'capitalize'}>
        {headerName}
      </Typography>
    </Grid>
  );
};

//render column cells
const renderColCell = (value: string, background?: string, color?: string) => {
  return (
    <Grid container justifyContent={'center'} alignItems={'center'}>
      <Chip
        size='small'
        sx={{
          background: background,
          color: color,
        }}
        label={
          <Typography
            variant='button'
            sx={{
              fontSize: '10px',
              textTransform: 'uppercase',
              lineHeight: 1.5,
            }}>
            {value}
          </Typography>
        }
      />
    </Grid>
  );
};
const renderConstCell = (value: string) => {
  return (
    <Grid container justifyContent={'center'} alignItems={'center'}>
      <Typography
        sx={{
          fontSize: '10px',
          textTransform: 'uppercase',
          lineHeight: 1.5,
        }}>
        {value}
      </Typography>
    </Grid>
  );
};

const ProgramTrackCell = (props: any) => {
  const [ptcmData, setPtcmData] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const unsubscribe = await getPtcmDataInReviewByClientId(props.row.id, (data: any) => {
        setPtcmData(data);
      });
      return () => {
        unsubscribe();
      };
    };

    fetchData();
  }, [props.row.id]);

  if (ptcmData?.length > 0) {
    return (
      <List disablePadding sx={{ display: 'flex', flexDirection: 'row' }}>
        {ptcmData.map((pt: any) => (
          <ListItem disablePadding disableGutters key={pt.id}>
            {renderColCell(pt.programTrackName)}
          </ListItem>
        ))}
      </List>
    );
  } else {
    return renderColCell(props.row.programTrack ? props.row.programTrack : '...');
  }
};

//data grid columns

const ClientsList = () => {
  const user: UserModel = useAppSelector((state: any) => state.user.user);
  const navigate = useNavigate();
  const PAGE_SIZE = 10;
  const [invitePCModal, setInvitePCModal] = React.useState(false);
  const [filterModal, setFilterModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [clientMetaInfo, setClientMetaInfo] = React.useState<ICLientMetaInfo>();
  const [clients, setClients] = React.useState<Array<ICommonClient>>([]);
  const [deleteClientId, setDeleteClientId] = React.useState(String);
  const [unenrollClientId, setUnenrollClientId] = React.useState(String);
  const [deleteClientStatus, setDeleteClientStatus] = React.useState(String);
  const [deleteAlert, setDeleteAlert] = React.useState(false);
  const [unEnrollAlert, setUnenrollAlert] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState<string>('');
  const [searchQueryType, setSearchQueryType] = React.useState<string>('');
  const [isSearchMode, setIsSearchMode] = React.useState(false);
  const [isFilterMode, setIsFilterMode] = React.useState(false);
  const [status, setStatus] = React.useState('all');
  const [sortModel, setSortModel] = useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: PAGE_SIZE,
  });

  const [searchPaginationModel, setSearchPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });
  const [filterPaginationModel, setFilterPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });

  const [infoSessionData, setInfoSessionData] = useState<InfoSessionEditModel[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<{ [key: string]: string[] }>({});

  const handleCheckboxChange = (filterId: string, optionId: string) => {
    setSelectedOptions((prevState) => {
      const currentSelections = prevState[filterId] || [];
      const isSelected = currentSelections.includes(optionId);

      const newSelections = isSelected
        ? currentSelections.filter((id) => id !== optionId)
        : [...currentSelections, optionId];

      return {
        ...prevState,
        [filterId]: newSelections,
      };
    });
  };

  const getInfoSessionList = async () => {
    try {
      const unsubscribe = await fetchInfoSessionList((sessionsList: any) => {
        setInfoSessionData(sessionsList);
      });
      return () => {
        unsubscribe();
      };
    } catch (err: any) {
      toast.error(err?.response?.data?.message || err?.message);
    }
  };

  useEffect(() => {
    getInfoSessionList();
  }, []);

  const currentDate: any = dayjs();
  const currentSeconds = Math.floor(currentDate / 1000);

  const genClientsList = async (clientsList: []) => {
    return clientsList.map((clientInfo: any) => {
      const firstName = clientInfo.navigatorInfo?.firstName;
      const lastName = clientInfo.navigatorInfo?.lastName;
      return {
        ...clientInfo,
        client: clientInfo.firstName + ' ' + clientInfo.lastName,
        status: clientInfo.status,
        programTrack: clientInfo.programTrackInfo?.name,
        progress: 80,
        navigator: firstName && lastName ? `${firstName} ${lastName}` : 'Unassigned',
        rollingTime: clientInfo.clientMapData?.startDate?.seconds > currentSeconds,
        cohortTime: clientInfo.programTrackInfo?.startTime?.seconds > currentSeconds,
        infoSessionData: infoSessionData.find((data: any) => data.id === clientInfo.infoSessionId) || '',
      };
    });
  };

  const loadData = async () => {
    try {
      setLoading(true);
      setSearchQuery('');
      setIsSearchMode(false);
      setIsFilterMode(false);
      const result: any = await listClientByQuery(paginationModel.page + 1, status, sortModel, user);

      if (result.clients?.length) {
        setClientMetaInfo(result);
        const clientsRows = genClientsList(result.clients);
        setClients(await clientsRows);
      } else {
        setClientMetaInfo(result);
        setClients([]);
      }
      setLoading(false);
    } catch (err: any) {
      toast.error(err.message);
      setLoading(false);
    }
  };

  const handleFilter = async () => {
    try {
      setLoading(true);
      setSearchQuery('');
      setIsFilterMode(true);
      setIsSearchMode(false);
      const result: any = await listClientByFiltersApplied(
        filterPaginationModel.page + 1,
        selectedOptions,
        sortModel,
        user
      );

      if (result.clients?.length) {
        setClientMetaInfo(result);
        const clientsRows = genClientsList(result.clients);
        setClients(await clientsRows);
      } else {
        setClientMetaInfo(result);
        setClients([]);
      }
      setLoading(false);
    } catch (err: any) {
      toast.error(err.message);
      setLoading(false);
    }
  };

  const columns = [
    {
      field: 'client',
      headerName: 'Client',
      type: 'string',
      sortable: true,
      width: 200,
      renderCell: (params: any) => {
        return (
          <Grid container justifyContent={'left'} alignItems={'center'}>
            <Avatar src='/broken-image.jpg' sx={{ width: 24, height: 24, mr: 1 }} />
            <Typography variant='button' sx={{ textTransform: 'capitalize', lineHeight: 1.5 }}>
              {params.row.client}
            </Typography>
          </Grid>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'string',
      sortable: true,
      width: 300,
      renderHeader: (params: any) => {
        return renderColHeader(params.colDef.headerName);
      },
      renderCell: (params: any) => {
        return renderColCell(
          params.row.status.split('_').join(' '),
          getStatusColors(params.row.status)?.bgColor,
          getStatusColors(params.row.status)?.textColor
        );
      },
    },
    {
      field: 'navigator',
      headerName: 'Navigator',
      type: 'string',
      sortable: true,
      width: 300,
      renderHeader: (params: any) => {
        return renderColHeader(params.colDef.headerName);
      },
      renderCell: (params: any) => {
        const navigator = params.row.navigator;
        return navigator === 'Unassigned'
          ? renderConstCell(navigator)
          : renderColCell(navigator, undefined, COLORS.palette.green);
      },
    },
    {
      field: 'programTrack',
      headerName: 'Program Track',
      type: 'string',
      sortable: true,
      width: 300,
      renderHeader: (params: any) => {
        return renderColHeader(params.colDef.headerName);
      },
      renderCell: (params: any) => <ProgramTrackCell row={params.row} />,
    },
    {
      field: 'interestedInData',
      headerName: 'Interest',
      type: 'string',
      sortable: true,
      width: 200,
      renderHeader: (params: any) => {
        return renderColHeader(params.colDef.headerName);
      },
      renderCell: (params: any) => {
        return renderColCell(params.row.careerPathInfo ? params.row.careerPathInfo.name : '...');
      },
    },
    {
      headerName: 'Actions',
      sortable: false,
      width: 300,
      renderCell: (params: any) => (
        <Box display={'flex'} gap={2}>
          {user?.role === UserRolesEnum.SUPER_ADMIN ? (
            <Box>
              <IconButton
                color='error'
                onClick={(e) => {
                  e.stopPropagation();
                  setDeleteClientId(params.row.id);
                  setDeleteClientStatus(params.row.status);
                  setDeleteAlert(true);
                }}>
                <DeleteIcon
                  style={{
                    color: COLORS.palette.red,
                  }}
                />
              </IconButton>
              {params.row.status === ClientStatus.ENROLLED &&
              ((params.row.programTrackInfo.type === programTrackTypes.ROLLING.value &&
                params.row?.rollingTime === true) ||
                (params.row?.programTrackInfo.type === programTrackTypes.COHORT.value &&
                  params.row?.cohortTime === true)) ? (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setUnenrollClientId(params.row.id);
                    setUnenrollAlert(true);
                  }}>
                  <Button size='small' type='submit' variant='outlined' color='info'>
                    Unenroll
                  </Button>
                </IconButton>
              ) : null}
            </Box>
          ) : null}
        </Box>
      ),
    },
  ];

  const handleDelete = async () => {
    try {
      if (deleteClientStatus.toLowerCase() === ClientStatus.ENROLLED) {
        toast.error('Client is currently Enrolled!');
      } else {
        await deleteClient(deleteClientId);
        loadData();
        toast.success('Client Deleted Successfully!');
      }
    } catch (err: any) {
      toast.error('Error Deleting Client!');
      console.error(err?.response.data.message || err?.message);
    }
  };

  const handleUnenroll = async () => {
    const response: any = await unenrollClient(unenrollClientId);
    if (response.success) {
      toast.success('Client Unenrolled Successfully!');
    }
    loadData();
  };

  React.useEffect(() => {
    if (!isSearchMode) {
      loadData();
    }
    // eslint-disable-next-line
  }, [paginationModel.page, status]);

  React.useEffect(() => {
    if (isFilterMode) {
      handleFilter();
    }
    // eslint-disable-next-line
  }, [filterPaginationModel.page, status]);

  const handleSearch = async () => {
    if (searchQuery) {
      setLoading(true);
      setIsSearchMode(true);
      setIsFilterMode(false);
      const result: any = await searchClients(
        searchPaginationModel.page + 1,
        searchQuery.toLowerCase(),
        searchQueryType,
        status,
        user
      );
      if (result.clients.length > 0) {
        setClientMetaInfo(result);
        const clientsRows = genClientsList(result.clients);
        setClients(await clientsRows);
      } else {
        setClientMetaInfo(result);
        setClients([]);
      }
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (isSearchMode) {
      handleSearch();
    } else {
      loadData();
    }
    // eslint-disable-next-line
  }, [searchPaginationModel.page, status, sortModel]);

  React.useEffect(() => {
    if (!searchQuery) {
      loadData();
    }
    // eslint-disable-next-line
  }, [searchQuery]);

  const handleSortModelChange = (newSortModel: any) => {
    setSortModel(newSortModel);
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ width: '100%', height: 500 }}>
          <CustomTable
            rows={clients}
            columns={columns}
            onRowClick={(params: any) => {
              if (String(params.row.status).toLowerCase() === ClientStatus.PROSPECTIVE) {
                navigate('../prospective-client-info', {
                  state: { clientId: params.row.id },
                });
              } else {
                navigate('../clientInfo', {
                  state: { clientId: params.row.id, programTrackId: params.row.programTrackInfo?.id || null },
                });
              }
            }}
            filterList={ClientListStatuses}
            loading={loading}
            disbaleMultiSelect={true}
            showFilterButton={true}
            onFilterListChange={(list: any) => setStatus(list[0] || 'all')}
            paginationModel={
              isSearchMode ? searchPaginationModel : isFilterMode ? filterPaginationModel : paginationModel
            }
            onPaginationModelChange={
              isSearchMode ? setSearchPaginationModel : isFilterMode ? setFilterPaginationModel : setPaginationModel
            }
            rowCount={clientMetaInfo?.totalRecords || 0}
            title='Clients'
            primaryButtonIcon={<PersonAdd />}
            primaryButtonLable='Invite Prospective Client'
            filterButtonAction={() => setFilterModal(true)}
            primaryButtonAction={() => setInvitePCModal(true)}
            secondaryButtonIcon={<PersonAdd />}
            disabled={!shouldShowButton('inviteProspectiveClient', user?.role)}
            disableSecondaryButton={!shouldShowButton('createClient', user?.role)}
            secondaryButtonLable='Create Client'
            secondaryButtonAction={() => navigate('/admin/createClient')}
            onChangeSearchQuery={(val: string) => {
              setSearchQuery(val);
              if (EMAIL_REGEX.test(val)) {
                setSearchQueryType('email');
              } else if (MOBILE_REGEX.test(val)) {
                setSearchQueryType('mobile');
              } else {
                setSearchQueryType('name');
              }
            }}
            handleSearch={() => handleSearch()}
            searchQuery={searchQuery}
            searchBoxPlaceHolder='Type First Name to Search'
            onSortModelChange={handleSortModelChange}
            sortModel={sortModel}
          />
          <GenericDialog
            onClose={() => setDeleteAlert(false)}
            dialogTitle='Are you sure?'
            agreeBtnText='Delete'
            disagreeBtnText='Cancel'
            agreeFunc={() => handleDelete()}
            open={deleteAlert}
          />
          <GenericDialog
            onClose={() => setUnenrollAlert(false)}
            dialogTitle='Are you sure?'
            agreeBtnText='Unenroll'
            disagreeBtnText='Cancel'
            agreeFunc={() => handleUnenroll()}
            open={unEnrollAlert}
          />
        </Box>
      </Box>
      <AddProspectiveClientModal open={invitePCModal} onClose={() => setInvitePCModal(false)} />
      <FilterModal
        open={filterModal}
        onClose={() => setFilterModal(false)}
        handleFilter={handleFilter}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
        handleCheckboxChange={handleCheckboxChange}
        isClientList={true}
      />
    </>
  );
};

export default ClientsList;
