import React, { useState, useEffect } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Checkbox,
} from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import { COLORS } from '../../../utils/colors';
import { getJobByJobId } from '../../../services/employmentModule.service';
import dayjs from 'dayjs';
import { ITiming } from '../../../store/employmentModule/employmentModal';
import { LoadingButton } from '../../LoadingButton/LoadingButton';
import { CheckCircleOutlineOutlined } from '@mui/icons-material';
import { markJobInterviewAttendance } from '../../../services/client.service';

interface IInterviewDetailsProps {
  open: boolean;
  onClose: Function;
  clientInfo: any;
}

interface IJobDetail {
  id: number;
  title: any;
  employer: any;
  timings: string;
  jobId: string;
  attendance?: string; // Include attendance field
}

const ClientScheduledInterviewDetails = (props: IInterviewDetailsProps) => {
  const { open, onClose, clientInfo } = props;
  const [resolvedTableRows, setResolvedTableRows] = useState<Array<IJobDetail>>([]);
  const [selectedJobIds, setSelectedJobIds] = useState<Array<string>>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const tableCols = [
    { id: 'id', name: '#' },
    { id: 'title', name: 'Job Title' },
    { id: 'employer', name: 'Employer' },
    { id: 'timings', name: 'Available Timings' },
    { id: 'attendance', name: 'Attendance' },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const formatDates = (dates: Array<ITiming>) => {
        const formattedDates = dates.map((item: ITiming) => {
          return dayjs(item.timing).format('MM/DD/YYYY, HH:mm');
        });
        return formattedDates.join(' | ');
      };
      try {
        const resolvedRows = await Promise.all(
          clientInfo?.scheduledJobInterview.map(async (item: any, index: number) => {
            const jobData: any = await getJobByJobId(item.jobId);
            const attendanceObject = clientInfo.jobInterviewAttendance?.find((att: any) => att.jobId === jobData.id);
            const attendance = attendanceObject?.attendance; // Get attendance if present

            return {
              id: index + 1,
              title: jobData?.title || '',
              employer: jobData?.employer || '',
              timings: formatDates(jobData.availableTimings),
              jobId: jobData?.id || '',
              attendance, // Include attendance in the result
            };
          })
        );
        setResolvedTableRows(resolvedRows);
      } catch (error) {
        console.error('Error fetching job details:', error);
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, [clientInfo, clientInfo?.scheduledJobInterview, clientInfo?.jobInterviewAttendance]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, jobId: string) => {
    if (event.target.checked) {
      setSelectedJobIds((prevJobIds) => [...prevJobIds, jobId]);
    } else {
      setSelectedJobIds((prevJobIds) => prevJobIds.filter((id) => id !== jobId));
    }
  };

  const handleMarkAttendance = async () => {
    try {
      setLoading(true);
      await markJobInterviewAttendance(clientInfo?.id, selectedJobIds);
      setLoading(false);
      onClose();
      setSelectedJobIds([]);
    } catch (error) {
      // handle error
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth={'md'}
      open={open}
      onClose={() => {
        onClose();
        setSelectedJobIds([]);
      }}
      PaperProps={{ sx: { borderRadius: '14px' } }}>
      <DialogTitle height={70} borderBottom={`1px solid ${COLORS.palette.borderColor}`}>
        <Grid container direction='row' justifyContent='space-between' alignItems='center' marginBottom='20px'>
          <Box>
            <Typography variant='h6'>Scheduled Job Interview Details</Typography>
          </Box>
          <IconButton
            onClick={() => {
              onClose();
              setSelectedJobIds([]);
            }}>
            <GridCloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box>
          <TableContainer
            component={Paper}
            sx={{ boxShadow: 'none', border: `1px solid ${COLORS.palette.borderColor}` }}>
            <Table sx={{ minWidth: 600 }} size='small'>
              <TableHead>
                <TableRow>
                  {tableCols.map((col: any, index: any) => (
                    <TableCell sx={{ fontWeight: 'bold' }} key={index}>
                      {col.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {resolvedTableRows?.map((row: IJobDetail, index: number) => (
                  <TableRow key={index}>
                    {tableCols.map((col: any, colIndex: number) => (
                      <TableCell key={colIndex}>
                        {col.id === 'attendance' && row.attendance ? (
                          // Show attendance if present
                          <Typography variant='body2'>{row.attendance}</Typography>
                        ) : col.id === 'attendance' ? (
                          // Show checkbox if attendance is not present
                          <Checkbox
                            checked={selectedJobIds.includes(row.jobId)}
                            onChange={(event) => handleCheckboxChange(event, row.jobId)}
                          />
                        ) : (
                          (row as any)[col.id]
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </DialogContent>
      <Grid container direction='column' alignItems='center' justifyContent='center' py={2}>
        <Grid item xs={12}>
          <LoadingButton
            size='medium'
            color={'primary'}
            variant={'contained'}
            loading={loading}
            disabled={selectedJobIds.length > 0 ? false : true}
            onClick={handleMarkAttendance}
            startIcon={<CheckCircleOutlineOutlined />}
            typographyComp={
              <Typography variant='body2' textTransform={'none'} noWrap>
                Mark Attendance
              </Typography>
            }
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ClientScheduledInterviewDetails;
