import { Divider, Grid, List, ListItem, ListItemText, Typography } from '@mui/material';
import { InfoSessionModel } from '../../store/infoSession/infoSessionModel';
import dayjs from 'dayjs';
import { Palette } from '../../utils/palette';

export type IClientInfoSession = {
  data: InfoSessionModel;
};

export const ClientInfoSession = (props: IClientInfoSession) => {
  const { data } = props;

  return (
    <Grid container>
      <Grid item xs={12} lg={4}></Grid>
      <Grid item xs={12} lg={4}>
        <List sx={LocalStyles.list}>
          <Typography variant='h6' sx={LocalStyles.headline}>
            Your Scheduled Info Session
          </Typography>

          <Divider />

          <ListItem>
            <ListItemText
              primary='Date:'
              primaryTypographyProps={LocalStyles.listItemPrimaryProp}
              secondary={data?.dateTime ? dayjs(data.dateTime).tz('America/New_York').format('MM/DD/YYYY') : '---'}
              secondaryTypographyProps={LocalStyles.listItemSecondaryProp}
            />
          </ListItem>

          <ListItem>
            <ListItemText
              primary='Title:'
              primaryTypographyProps={LocalStyles.listItemPrimaryProp}
              secondary={data?.title ? data?.title : '---'}
              secondaryTypographyProps={LocalStyles.listItemSecondaryProp}
            />
          </ListItem>

          <ListItem>
            <ListItemText
              primary='Description:'
              primaryTypographyProps={LocalStyles.listItemPrimaryProp}
              secondary={data?.description ? data?.description : '---'}
              secondaryTypographyProps={LocalStyles.listItemSecondaryProp}
            />
          </ListItem>

          <ListItem>
            <ListItemText
              primary='HRA:'
              primaryTypographyProps={LocalStyles.listItemPrimaryProp}
              secondary={data?.hra ? data?.hra : '---'}
              secondaryTypographyProps={LocalStyles.listItemSecondaryProp}
            />
          </ListItem>

          <ListItem>
            <ListItemText
              primary='Location:'
              primaryTypographyProps={LocalStyles.listItemPrimaryProp}
              secondary={data?.location ? data?.location : '---'}
              secondaryTypographyProps={LocalStyles.listItemSecondaryProp}
            />
          </ListItem>

          <ListItem>
            <ListItemText
              primary='Session Type:'
              primaryTypographyProps={LocalStyles.listItemPrimaryProp}
              secondary={data?.sessionType ? data?.sessionType : '---'}
              secondaryTypographyProps={LocalStyles.listItemSecondaryProp}
            />
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={12} lg={4}></Grid>
    </Grid>
  );
};

const LocalStyles = {
  headline: {
    py: 2,
    justifyContent: 'center',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  list: {
    width: '100%',
    background: Palette.gray_F5F5F5,
    borderRadius: 4,
  },
  listItemPrimaryProp: {
    fontWeight: 'bold',
    textAlign: 'center' as const,
  },
  listItemSecondaryProp: {
    textAlign: 'center' as const,
  },
};
