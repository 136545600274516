import React, { useState } from 'react';
import { useAppSelector } from '../../../store/store';
import { UserModel } from '../../../store/user/userModel';
import { getClientInfoById } from '../../../services/client.service';
import { Box, Typography } from '@mui/material';
import ClientDocuments from '../../../components/ClientDocuments/ClientDocuments';

export default function ClientDocumentsPage() {
  const user: UserModel = useAppSelector((state: any) => state.user.user);
  const [clientInfo, setClientInfo] = useState(undefined);

  React.useEffect(() => {
    if (user?.id) {
      (async () => {
        const client: any = await getClientInfoById(user.id);
        setClientInfo(client);
      })();
    }
    // eslint-disable-next-line
  }, [user?.id]);

  return (
    <Box p={5}>
      <Typography variant='h2'>Client Documents</Typography>
      {clientInfo ? <ClientDocuments clientInfo={clientInfo} /> : null}
    </Box>
  );
}
