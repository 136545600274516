import React, { useState, useEffect, useRef } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import { COLORS } from '../../utils/colors';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { emptyJobInfo, IJobInfo, IJobTimings } from '../../store/employmentModule/employmentModal';
import { addNewInterviewTimingInJob, scheduleClientJobInterview } from '../../services/employmentModule.service';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { LoadingButton } from '../LoadingButton/LoadingButton';
import { UserModel } from '../../store/user/userModel';
import AddDateTime from '../Modals/AddDateTime/AddDateTime';
import { WriteModeEnum } from '../../utils/writeModeEnum';

interface IScheduleJobInterviewProps {
  showLiaisonForm: boolean;
  clientInfo: any;
  user: UserModel;
  fetchJobData: Function;
  jobList: IJobInfo[];
}

const ScheduleClientJobInterview = (props: IScheduleJobInterviewProps) => {
  const { showLiaisonForm, clientInfo, user, fetchJobData, jobList } = props;
  const [selectedJob, setSelectedJob] = useState<IJobInfo>(emptyJobInfo);
  const [scheduleJobLoading, setScheduleJobLoading] = useState<boolean>(false);
  const [selectedAvlTimings, setSelectedAvlTimings] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [dateTime, setDateTime] = useState<string>('');
  const [dateTimeModal, setDateTimeModal] = useState<boolean>(false);
  const prevSelectedJobRef = useRef<IJobInfo>(emptyJobInfo);

  useEffect(() => {
    prevSelectedJobRef.current = selectedJob;
  }, [selectedJob]);

  useEffect(() => {
    const jobData = jobList.find((job) => job.id === prevSelectedJobRef.current.id);
    if (jobData) {
      setSelectedJob(jobData);
    } else {
      setSelectedJob(emptyJobInfo);
    }
  }, [jobList]);

  const handleAvlTimingToggle = (timing: string) => {
    if (selectedAvlTimings.includes(timing)) {
      const avlTimings = selectedAvlTimings.filter((item: string) => item !== timing);
      setSelectedAvlTimings(avlTimings);
    } else {
      const avlTimings = [...selectedAvlTimings, timing];
      setSelectedAvlTimings(avlTimings);
    }
  };

  const handleInterviewSchedule = async () => {
    try {
      setScheduleJobLoading(true);

      let response: any = await scheduleClientJobInterview(
        [clientInfo.id],
        selectedJob.id,
        user?.id || '',
        selectedAvlTimings
      );

      if (response?.success) {
        toast.success(response.message);
      } else {
        toast.error('Something went wrong');
      }
      setScheduleJobLoading(false);
      setSelectedJob(emptyJobInfo);
      fetchJobData();
    } catch (error) {
      console.error('Error scheduling interview:', error);
      setScheduleJobLoading(false);
    }
  };

  const handleSelectJob = (newValue: { id: string; label: string }) => {
    const jobData: IJobInfo | undefined = jobList.find((job: IJobInfo) => job.id === newValue.id);
    setSelectedJob(jobData ? jobData : emptyJobInfo);
  };

  const handleClose = () => {
    setDateTimeModal(!dateTimeModal);
    setDateTime('');
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await addNewInterviewTimingInJob(selectedJob.id, dateTime);
      handleClose();
      fetchJobData();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      //catch err
    }
  };

  return (
    <Box
      sx={{ background: COLORS.card.cardBg }}
      borderRadius={'10px'}
      p={2}
      my={2}
      display={showLiaisonForm && !clientInfo.employment && clientInfo.employmentLiaisonId ? 'block' : 'none'}>
      <Typography variant='h6'>Schedule Client Job Interview</Typography>
      <Box pb={2}>
        <Divider variant='fullWidth' light={true} />
      </Box>

      <Grid item xs={12}>
        <Autocomplete
          value={{ id: selectedJob.id, label: selectedJob.title }}
          disableClearable
          size='small'
          onChange={(event: any, newValue: any) => {
            handleSelectJob(newValue);
          }}
          options={jobList.map((option: any) => ({
            id: option.id,
            label: option.title,
          }))}
          getOptionLabel={(option: any) => option.label}
          renderInput={(params) => <TextField {...params} label='Select Job' />}
        />
        {selectedJob.preHireRequirements && selectedJob.preHireRequirements.length > 0 ? (
          <>
            <Typography sx={{ mt: 2 }} variant='h6'>
              Pre-Hire Requirements
            </Typography>
            <List sx={{ paddingTop: 0, paddingBottom: 0, ml: 2, mb: 2 }}>
              {selectedJob.preHireRequirements.map((requirement) => (
                <ListItem key={requirement.id} disableGutters sx={{ pd: 0, pb: 0 }}>
                  <ListItemIcon style={{ minWidth: 'unset', marginRight: '8px' }}>
                    <FiberManualRecordIcon style={{ fontSize: '10px', color: 'black' }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant='body1' style={{ fontWeight: 500, fontSize: '16px' }}>
                        {requirement.value}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </>
        ) : null}

        {selectedJob.availableTimings.length > 0 && (
          <>
            <Typography sx={{ mt: 2 }} variant='h6'>
              Schedule Job Interview
            </Typography>
            <FormControl>
              <List>
                {selectedJob.availableTimings.map((option: IJobTimings) => {
                  const labelId = `checkbox-list-secondary-label-${option.timing}`;
                  const isChecked = selectedAvlTimings.includes(option.timing);

                  return (
                    <ListItem key={option.timing} disablePadding>
                      <Checkbox
                        size='small'
                        onChange={() => handleAvlTimingToggle(option.timing)}
                        checked={isChecked}
                        sx={{ alignSelf: 'flex-start' }}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                      <ListItemText
                        id={labelId}
                        primary={dayjs(option.timing).tz('America/New_York').format('MM/DD/YYYY hh:mm A')}
                        sx={{ fontSize: '16px' }}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </FormControl>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button
                sx={{ textDecoration: 'underline' }}
                onClick={() => {
                  setDateTimeModal(!dateTimeModal);
                }}>
                {'*Add New Interview Date'}
              </Button>
            </Box>
          </>
        )}

        <Box display='flex' justifyContent='center' mt={2}>
          <LoadingButton
            onClick={handleInterviewSchedule}
            label='Submit'
            styles={{
              textTransform: 'none',
            }}
            loading={scheduleJobLoading}
            disabled={!selectedJob.id || selectedAvlTimings.length < 1}
            size='medium'
            type='submit'
            variant='contained'
          />
        </Box>
      </Grid>
      <AddDateTime
        open={dateTimeModal}
        onClose={handleClose}
        mode={WriteModeEnum.CREATE}
        handleSubmit={handleSubmit}
        setDateTime={setDateTime}
        dateTime={dateTime}
        loading={loading}
        isAddInterviewDate={true}
      />
    </Box>
  );
};

export default ScheduleClientJobInterview;
