import React, { useState, useEffect } from 'react';
import {
  Autocomplete,
  Avatar,
  Box,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import {
  getEmploymentLiaisonList,
  getNavigatorList,
  getTrainerList,
  getUserInfoById,
} from '../../../services/user.service';
import { UserModel } from '../../../store/user/userModel';
import { useAppSelector } from '../../../store/store';
import { UserRolesEnum } from '../../../utils/rolesEnum';
import CustomTable from '../../../components/CustomTable';
import {
  getAllClients,
  getClientListByEmpLiaisonId,
  getClientListByNavigatorId,
} from '../../../services/client.service';
import { getStatusColors } from '../client/utils';
import { EmpPipeline, ReportUtils, caseLoadRoles } from '../../../utils/reportUtils';
import {
  getClientsByPTForFullAttendanceReport,
  getClientsByProgramTrackForReport,
  getCompletedClientsInLastXDays,
  getCompletedMilestonesByClientId,
  getLatestPTCMByProgramTrackId,
} from '../../../services/programTrackClientMap.service';
import { addDaysToDate, convertTimeStampToDate } from '../../../utils/dateTime';
import {
  getPTsForFullAttendaceReport,
  getProgramTracksByTrainerId,
  programTrackInfoById,
} from '../../../services/programTrack.service';
import { Person4, WorkspacePremiumOutlined } from '@mui/icons-material';
import { ClientNotes } from '../../../components/ClientNotes/ClientNotes';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

const Report = () => {
  const navigate = useNavigate();
  const user: UserModel = useAppSelector((state: any) => state.user.user);
  const [loading, setLoading] = useState<boolean>(false);

  const PAGE_SIZE = 10;
  const [metaInfo, setMetaInfo] = useState<any>();
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: PAGE_SIZE,
  });

  const [programTrack, setProgramTrack] = useState<any>(undefined);
  const [trainer, setTrainer] = useState<any>(undefined);
  const [navigatorList, setNavigatorList] = useState<any[]>([]);
  const [empLiaisonList, setEmpLiaisonList] = useState<any[]>([]);
  const [trainerList, setTrainerList] = useState<any[]>([]);
  const [programTrackList, setProgramTrackList] = useState<any[]>([]);
  const [ptListForFullAttendaceReport, setPTListForFullAttendaceReport] = useState<any[]>([]);
  const [clientList, setClientList] = useState<any[]>([]);
  const [programTrackClientList, setProgramTrackClientList] = useState<any[]>([]);
  const [dataTable, setDataTable] = useState<any[]>([]);

  const [selectedClient, setSelectedClient] = useState<any>(undefined);
  const [selectedNavigator, setSelectedNavigator] = useState<any>({ label: '', id: 0 });
  const [selectedEmpLiaison, setSelectedEmpLiaison] = useState<any>({ label: '', id: 0 });
  const [selectedCaseLoadRole, setSelectedCaseLoadRole] = useState<any>(null);
  const [selectedPTForAttendanceReport, setSelectedPTForAttendanceReport] = useState<any>(undefined);
  const [selectedTrainer, setSelectedTrainer] = useState<any>(undefined);
  const [selectedProgramTrack, setSelectedProgramTrack] = useState<any>(undefined);
  const [selectedTenure, setSelectedTenure] = useState<any>(undefined);
  const [selectedReport, setSelectedReport] = useState<any>(undefined);

  const clearPaging = () => {
    setPaginationModel({
      page: 0,
      pageSize: PAGE_SIZE,
    });
  };
  const getCompletionDate = (result: any, index: number) => {
    if (result.completedMilestones) {
      const currentMilestoneIndex = parseInt(result?.currentMilestoneIndex);
      if (
        (result.ptcmStatus === 'active' || result.ptcmStatus === 'pre_complete' || result.ptcmStatus === 'complete') &&
        index >= 0
      ) {
        if (result.completedMilestones[index]?.completionDate) {
          return result.completedMilestones[index].completionDate;
        } else if (currentMilestoneIndex && currentMilestoneIndex === index) {
          return 'In Progress';
        } else if (currentMilestoneIndex && currentMilestoneIndex < index) {
          return '--';
        } else if (index === 0) {
          return 'In Progress';
        } else {
          return '--';
        }
      } else if (result.ptcmStatus === 'drop_out' && index >= 0) {
        if (result.completedMilestones[index]?.completionDate) {
          return result.completedMilestones[index].completionDate;
        } else if (currentMilestoneIndex && currentMilestoneIndex === index) {
          return 'Dropout';
        } else if (currentMilestoneIndex && currentMilestoneIndex < index) {
          return '--';
        } else if (index === 0) {
          return 'Dropout';
        } else {
          return '--';
        }
      }
    }
  };

  const loadReport = async () => {
    if (selectedReport?.id === 1) {
      if (selectedCaseLoadRole?.id === 1) {
        const result = await getClientListByNavigatorId(selectedNavigator?.id, paginationModel.page + 1);
        if (result?.clients.length) {
          setMetaInfo(result);
          setDataTable(result?.clients);
        }
      } else if (selectedCaseLoadRole?.id === 2) {
        const unsubscribe: any = getClientListByEmpLiaisonId(
          selectedEmpLiaison?.id,
          paginationModel.page + 1,
          (result: any) => {
            if (result?.clients.length) {
              setMetaInfo(result);
              setDataTable(result?.clients);
            }
          }
        );
        return () => {
          unsubscribe();
        };
      }
    } else if (selectedReport?.id === 3) {
      const result = await getCompletedClientsInLastXDays(selectedTenure?.value, paginationModel.page + 1);
      if (result?.clients.length) {
        setMetaInfo(result);
        let dataNew: any = [];
        for (let i = 0; i < result?.clients?.length; i++) {
          if (result.clients[i].clientInfo) {
            dataNew.push({
              id: i,
              firstName: result.clients[i].clientInfo.firstName,
              lastName: result.clients[i].clientInfo.lastName,
              mobile: result.clients[i].clientInfo.mobile,
              email: result.clients[i].clientInfo.email,
              startTime: result.clients[i].startTime,
              endTime: result.clients[i].endTime,
            });
          }
        }
        setDataTable(dataNew);
      }
    } else if (selectedReport?.id === 2 || selectedReport?.id === 4) {
      if (selectedClient) {
        let result: any;
        if (selectedReport?.id === 4) {
          result = await getLatestPTCMByProgramTrackId(
            selectedClient?.id,
            selectedProgramTrack.id,
            paginationModel.page + 1
          );
          setMetaInfo(result);
        }
        if (selectedReport?.id === 2) {
          result = await getCompletedMilestonesByClientId(selectedClient?.id, paginationModel.page + 1);
          setMetaInfo(result);
        }

        if (selectedReport?.id === 2) {
          const info: any = await programTrackInfoById(result?.programTrackId || '');
          setProgramTrack(info);
          const trainerInfo: any = await getUserInfoById(info?.trainerId || '');
          setTrainer(trainerInfo);
        }

        const milestones = result?.milestones || [];
        const milestoneData: any = [];
        for (let i = 0; i < milestones.length; i++) {
          milestoneData.push({
            completionDate: getCompletionDate(result, i),
            name: milestones[i].name,
            id: i,
            deadline: result.startDate
              ? addDaysToDate(convertTimeStampToDate(result.startDate).toDate(), milestones[i]?.days)
              : convertTimeStampToDate(milestones[i]?.deadline).format('MM/DD/YYYY'),
          });
        }
        setDataTable([...milestoneData]);
      }
    } else if (selectedReport?.id === 5) {
      if (selectedPTForAttendanceReport) {
        const result = await getClientsByPTForFullAttendanceReport(
          selectedPTForAttendanceReport.id,
          paginationModel.page + 1
        );
        if (result?.clientDetail.length) {
          setMetaInfo(result);
          setDataTable(result.clientDetail);
        } else {
          setDataTable([]);
          setMetaInfo([]);
        }
      }
    }
  };

  const loadPTCPReport = async () => {
    if (selectedTrainer) {
      const res = await getProgramTracksByTrainerId(selectedTrainer?.id);
      setProgramTrackList(res);
    }
    if (selectedProgramTrack) {
      const res = await getClientsByProgramTrackForReport(selectedProgramTrack?.id);
      setProgramTrackClientList(res);
    }
    if (selectedClient) {
      loadReport();
    }
  };

  const loadOptions = async () => {
    if (selectedReport?.id === 1) {
      if (selectedCaseLoadRole?.id === 1) {
        const list = await getNavigatorList();
        setNavigatorList(list);
      } else if (selectedCaseLoadRole?.id === 2) {
        const unsubscribe: any = getEmploymentLiaisonList((list: any) => {
          setEmpLiaisonList(list);
        });
        return () => {
          unsubscribe();
        };
      }
    } else if (selectedReport?.id === 2) {
      const list = await getAllClients();
      setClientList([...list]);
    } else if (selectedReport?.id === 3 || selectedReport?.id === 4) {
      const list = await getTrainerList();
      setTrainerList(list);
    } else if (selectedReport?.id === 5) {
      const list = await getPTsForFullAttendaceReport();
      setPTListForFullAttendaceReport(list);
    }
  };
  const clearInputs = () => {
    setDataTable([]);
    clearPaging();
    setMetaInfo(undefined);
    setSelectedTrainer(undefined);
    setSelectedNavigator(undefined);
    setSelectedEmpLiaison(undefined);
    setSelectedClient(undefined);
    setSelectedProgramTrack(undefined);
    setSelectedTenure(undefined);
    setTrainer(undefined);
    setProgramTrack(undefined);
    setSelectedPTForAttendanceReport(undefined);
    setSelectedCaseLoadRole(undefined);
    setEmpLiaisonList([]);
  };

  const renderProgramTrackAndNavigator = () => {
    return (
      <Grid container sx={{ justifyContent: 'right', marginBottom: -10, py: 5 }}>
        <Grid item>
          <List disablePadding sx={{ display: 'flex' }}>
            <ListItem disablePadding disableGutters style={{ width: '300px' }}>
              <ListItemAvatar>
                <Avatar>
                  <WorkspacePremiumOutlined />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={programTrack?.name} secondary='Program Track' />
            </ListItem>
            <ListItem disablePadding disableGutters>
              <ListItemAvatar>
                <Avatar>
                  <Person4 />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={(trainer?.firstName || '') + ' ' + (trainer?.lastName || '')}
                secondary='Trainer'
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    );
  };

  //load navigator list
  useEffect(() => {
    (async () => {
      await loadOptions();
    })();
    // eslint-disable-next-line
  }, [selectedReport, selectedCaseLoadRole]);

  //clear inputs
  useEffect(() => {
    clearInputs();
    // eslint-disable-next-line
  }, [selectedReport]);

  //load client list
  useEffect(() => {
    (async () => {
      setLoading(true);
      await loadPTCPReport();
      setLoading(false);
    })();
    // eslint-disable-next-line
  }, [selectedTrainer, selectedProgramTrack, selectedClient]);

  //load client list
  useEffect(() => {
    (async () => {
      setLoading(true);
      await loadReport();
      setLoading(false);
    })();
    // eslint-disable-next-line
  }, [
    selectedNavigator,
    selectedEmpLiaison,
    selectedCaseLoadRole,
    selectedTenure,
    selectedPTForAttendanceReport,
    paginationModel.page,
  ]);

  const listCheckpoint = () => {
    if (user) {
      if (
        user.role === UserRolesEnum.ADMIN ||
        user.role === UserRolesEnum.SUPER_ADMIN ||
        user.role === UserRolesEnum.CASE_MANAGER ||
        user.role === UserRolesEnum.NAVIGATOR
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  //render column header
  const renderColHeader = (headerName: any) => {
    return (
      <Grid container sx={{ justifyContent: 'center', alignItems: 'center', width: 300 }} justifyContent={'center'}>
        <Typography variant='button' textTransform={'capitalize'}>
          {headerName}
        </Typography>
      </Grid>
    );
  };

  //render column cells
  const renderColCell = (value: string, background?: string, color?: string) => {
    return (
      <Grid container justifyContent={'center'} alignItems={'center'}>
        <Chip
          size='small'
          sx={{
            background: background,
            color: color,
          }}
          label={
            <Typography
              variant='button'
              sx={{
                fontSize: '10px',
                textTransform: 'uppercase',
                lineHeight: 1.5,
              }}>
              {value}
            </Typography>
          }
        />
      </Grid>
    );
  };

  const renderProgramTrackAndClient = () => {
    return (
      <Grid container spacing={1} pt={3}>
        <Grid item xs={12} sm={12} lg={6}>
          <Autocomplete
            value={selectedProgramTrack ? selectedProgramTrack : { label: '', id: 0 }}
            size='small'
            freeSolo
            disableClearable
            options={programTrackList?.map((item: any) => ({
              label: item.name,
              ...item,
            }))}
            getOptionLabel={(option: any) => {
              return option.label;
            }}
            onChange={(event, object) => {
              setDataTable([]);
              clearPaging();
              setSelectedClient(undefined);
              setSelectedProgramTrack(object);
            }}
            renderInput={(params) => <TextField {...params} label='Select program track' />}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={6}>
          <Autocomplete
            value={selectedClient ? selectedClient : { label: '', id: 0 }}
            size='small'
            freeSolo
            disableClearable
            options={programTrackClientList?.map((item: any) => ({
              label: item.firstName + ' ' + item.lastName,
              id: item.id,
            }))}
            getOptionLabel={(option: any) => {
              return option.label;
            }}
            onChange={(event, object) => {
              clearPaging();
              setSelectedClient(object);
            }}
            renderInput={(params) => <TextField {...params} label='Select client' />}
          />
        </Grid>
      </Grid>
    );
  };

  const renderNavigatorAndEmpLiaisons = () => {
    if (selectedCaseLoadRole.id === 1) {
      return (
        <Grid container spacing={1} pt={3}>
          <Grid item xs={12} sm={12} lg={6}>
            <Autocomplete
              value={selectedNavigator ? selectedNavigator : { label: '', id: 0 }}
              size='small'
              freeSolo
              disableClearable
              options={
                user?.role === UserRolesEnum.NAVIGATOR
                  ? navigatorList
                      ?.filter((i: any) => i.id === user?.id)
                      ?.map((item: any) => ({
                        label: item.firstName + ' ' + item.lastName || '',
                        id: item.id,
                      }))
                  : navigatorList?.map((item: any) => ({
                      label: item.firstName + ' ' + item.lastName || '',
                      id: item.id,
                    }))
              }
              getOptionLabel={(option: any) => {
                return option.label;
              }}
              onChange={(event, object) => {
                clearPaging();
                setSelectedNavigator(object);
              }}
              renderInput={(params) => <TextField {...params} label='Select navigator' />}
            />
          </Grid>
        </Grid>
      );
    } else if (selectedCaseLoadRole?.id === 2) {
      return (
        <Grid container spacing={1} pt={3}>
          <Grid item xs={12} sm={12} lg={6}>
            <Autocomplete
              value={selectedEmpLiaison ? selectedEmpLiaison : { label: '', id: 0 }}
              size='small'
              freeSolo
              disableClearable
              options={empLiaisonList?.map((item: any) => ({
                label: item.firstName + ' ' + item.lastName || '',
                id: item.id,
              }))}
              getOptionLabel={(option: any) => {
                return option.label;
              }}
              onChange={(event, object) => {
                clearPaging();
                setSelectedEmpLiaison(object);
              }}
              renderInput={(params) => <TextField {...params} label='Select Employment Liaison' />}
            />
          </Grid>
        </Grid>
      );
    }
  };

  const renderReportSubType = () => {
    if (selectedReport?.id === 1) {
      return (
        <Autocomplete
          size='small'
          disableClearable
          value={selectedCaseLoadRole}
          options={caseLoadRoles.map((item: any) => ({
            title: item.title,
            id: item.id,
          }))}
          getOptionLabel={(option: any) => {
            return option.title;
          }}
          onChange={(event, object) => {
            clearInputs();
            setSelectedCaseLoadRole(object);
          }}
          renderInput={(params) => <TextField {...params} label='Select a Role' />}
        />
      );
    } else if (selectedReport?.id === 2) {
      return (
        <Autocomplete
          value={selectedClient ? selectedClient : { label: '', id: 0 }}
          size='small'
          freeSolo
          disableClearable
          options={clientList?.map((item: any) => ({
            label: item.firstName + ' ' + item.lastName,
            id: item.id,
          }))}
          getOptionLabel={(option: any) => {
            return option.label;
          }}
          onChange={(event, object) => {
            clearPaging();
            setSelectedClient(object);
          }}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id}>
                {option.label}
              </li>
            );
          }}
          renderInput={(params) => <TextField {...params} label='Select client' />}
        />
      );
    } else if (selectedReport?.id === 3) {
      return (
        <Autocomplete
          value={selectedTenure ? selectedTenure : { title: '', id: 0, value: '' }}
          size='small'
          freeSolo
          disableClearable
          options={EmpPipeline?.map((item: any) => ({
            title: item.title,
            id: item.id,
            value: item.value,
          }))}
          getOptionLabel={(option: any) => {
            return option.title;
          }}
          onChange={(event, object) => {
            clearPaging();
            setSelectedTenure(object);
          }}
          renderInput={(params) => <TextField {...params} label='Select Tenure' />}
        />
      );
    } else if (selectedReport?.id === 4) {
      return (
        <Autocomplete
          value={selectedTrainer ? selectedTrainer : { label: '', id: 0 }}
          size='small'
          freeSolo
          disableClearable
          options={trainerList?.map((item: any) => ({
            label: item.firstName + ' ' + item.lastName,
            id: item.id,
          }))}
          getOptionLabel={(option: any) => {
            return option.label;
          }}
          onChange={(event, object) => {
            clearPaging();
            setSelectedClient(undefined);
            setSelectedProgramTrack(undefined);
            setSelectedTrainer(object);
          }}
          renderInput={(params) => <TextField {...params} label='Select trainer' />}
        />
      );
    } else if (selectedReport?.id === 5) {
      return (
        <Autocomplete
          value={selectedPTForAttendanceReport ? selectedPTForAttendanceReport : { label: '', id: 0 }}
          size='small'
          freeSolo
          disableClearable
          options={ptListForFullAttendaceReport?.map((item: any) => ({
            label: item.name,
            id: item.id,
          }))}
          getOptionLabel={(option: any) => {
            return option.label;
          }}
          onChange={(event, object) => {
            clearPaging();
            setSelectedPTForAttendanceReport(object);
          }}
          renderInput={(params) => <TextField {...params} label='Select program track' />}
        />
      );
    }
  };

  const getColsByReportTypeId = () => {
    let array: any[] = [];
    if (selectedReport?.id === 1) {
      array = [
        {
          field: 'firstName',
          headerName: 'Client Name',
          type: 'string',
          sortable: true,
          width: 250,
          renderCell: (params: any) => {
            return (
              <Grid container justifyContent={'left'} alignItems={'center'}>
                <Typography variant='button' sx={{ textTransform: 'capitalize', lineHeight: 1.5 }}>
                  {params.row.firstName + ' ' + params.row.lastName}
                </Typography>
              </Grid>
            );
          },
        },
        {
          field: 'status',
          headerName: 'Status',
          type: 'string',
          sortable: true,
          width: 300,
          renderHeader: (params: any) => {
            return renderColHeader(params.colDef.headerName);
          },
          renderCell: (params: any) => {
            if (params.row?.status) {
              return renderColCell(
                params.row.status.split('_').join(' '),
                getStatusColors(params.row.status)?.bgColor,
                getStatusColors(params.row.status)?.textColor
              );
            }
          },
        },
      ];
    } else if (selectedReport?.id === 2 || selectedReport?.id == 4) {
      array = [
        {
          field: 'name',
          headerName: 'Milestone Name',
          type: 'string',
          sortable: true,
          width: 250,
          renderCell: (params: any) => {
            return (
              <Grid container justifyContent={'left'} alignItems={'center'}>
                <Typography variant='button' sx={{ textTransform: 'capitalize', lineHeight: 1.5 }}>
                  {params.row.name}
                </Typography>
              </Grid>
            );
          },
        },
        {
          field: 'completionDate',
          headerName: 'Completion Date',
          type: 'string',
          sortable: true,
          width: 300,
          renderHeader: (params: any) => {
            return renderColHeader(params.colDef.headerName);
          },
          renderCell: (params: any) => {
            return (
              <Grid container justifyContent={'center'} alignItems={'center'}>
                <Typography variant='button' sx={{ textTransform: 'capitalize', lineHeight: 1.5 }}>
                  {params.row?.completionDate !== '--' &&
                  params.row?.completionDate !== 'In Progress' &&
                  params.row?.completionDate !== 'Dropout'
                    ? convertTimeStampToDate(params.row.completionDate).format('MM/DD/YYYY')
                    : params.row?.completionDate}
                </Typography>
              </Grid>
            );
          },
        },
        {
          field: 'deadline',
          headerName: 'Due Date',
          type: 'string',
          sortable: true,
          width: 300,
          renderHeader: (params: any) => {
            return renderColHeader(params.colDef.headerName);
          },
          renderCell: (params: any) => {
            return (
              <Grid container justifyContent={'center'} alignItems={'center'}>
                <Typography variant='button' sx={{ textTransform: 'capitalize', lineHeight: 1.5 }}>
                  {params.row.deadline !== '--'
                    ? dayjs(params.row.deadline).format('MM/DD/YYYY')
                    : params.row?.deadline}
                </Typography>
              </Grid>
            );
          },
        },
      ];
    } else if (selectedReport?.id === 3) {
      array = [
        {
          field: 'firstName',
          headerName: 'Client Name',
          type: 'string',
          sortable: true,
          width: 250,
          renderCell: (params: any) => {
            return (
              <Grid container justifyContent={'left'} alignItems={'center'}>
                <Typography variant='button' sx={{ textTransform: 'capitalize', lineHeight: 1.5 }}>
                  {params.row.firstName + ' ' + params.row.lastName || ''}
                </Typography>
              </Grid>
            );
          },
        },
        {
          field: 'mobile',
          headerName: 'Phone Number',
          type: 'string',
          sortable: true,
          width: 300,
          renderHeader: (params: any) => {
            return renderColHeader(params.colDef.headerName);
          },
          renderCell: (params: any) => {
            return (
              <Grid container justifyContent={'center'} alignItems={'center'}>
                <Typography variant='button' sx={{ textTransform: 'capitalize', lineHeight: 1.5 }}>
                  {params.row.mobile || ''}
                </Typography>
              </Grid>
            );
          },
        },
        {
          field: 'email',
          headerName: 'Email Address',
          type: 'string',
          sortable: true,
          width: 300,
          renderHeader: (params: any) => {
            return renderColHeader(params.colDef.headerName);
          },
          renderCell: (params: any) => {
            return (
              <Grid container justifyContent={'center'} alignItems={'center'}>
                <Typography variant='button' sx={{ textTransform: 'capitalize', lineHeight: 1.5 }}>
                  {params.row.email || ''}
                </Typography>
              </Grid>
            );
          },
        },
        {
          field: 'startTime',
          headerName: 'Start Time',
          type: 'string',
          sortable: true,
          width: 300,
          renderHeader: (params: any) => {
            return renderColHeader(params.colDef.headerName);
          },
          renderCell: (params: any) => {
            return (
              <Grid container justifyContent={'center'} alignItems={'center'}>
                <Typography variant='button' sx={{ textTransform: 'capitalize', lineHeight: 1.5 }}>
                  {params.row?.startTime ? convertTimeStampToDate(params.row.startTime).format('MM/DD/YYYY') : '--'}
                </Typography>
              </Grid>
            );
          },
        },
        {
          field: 'endTime',
          headerName: 'End Time',
          type: 'string',
          sortable: true,
          width: 300,
          renderHeader: (params: any) => {
            return renderColHeader(params.colDef.headerName);
          },
          renderCell: (params: any) => {
            return (
              <Grid container justifyContent={'center'} alignItems={'center'}>
                <Typography variant='button' sx={{ textTransform: 'capitalize', lineHeight: 1.5 }}>
                  {params.row?.endTime ? convertTimeStampToDate(params.row.endTime).format('MM/DD/YYYY') : '--'}
                </Typography>
              </Grid>
            );
          },
        },
      ];
    } else if (selectedReport?.id === 5) {
      array = [
        {
          field: 'firstName',
          headerName: 'Client Name',
          type: 'string',
          sortable: true,
          width: 250,
          renderCell: (params: any) => {
            return (
              <Grid container justifyContent={'left'} alignItems={'center'}>
                <Typography variant='button' sx={{ textTransform: 'capitalize', lineHeight: 1.5 }}>
                  {params.row.firstName + ' ' + params.row.lastName || '--'}
                </Typography>
              </Grid>
            );
          },
        },
        {
          field: 'daysPresent',
          headerName: 'No. of Days Present',
          type: 'string',
          sortable: true,
          width: 250,
          renderCell: (params: any) => {
            return (
              <Grid container justifyContent={'left'} alignItems={'center'}>
                <Typography variant='button' sx={{ textTransform: 'capitalize', lineHeight: 1.5 }}>
                  {params.row.daysPresent || '--'}
                </Typography>
              </Grid>
            );
          },
        },
        {
          field: 'daysAbsent',
          headerName: 'No. of Days Absent',
          type: 'string',
          sortable: true,
          width: 250,
          renderCell: (params: any) => {
            return (
              <Grid container justifyContent={'left'} alignItems={'center'}>
                <Typography variant='button' sx={{ textTransform: 'capitalize', lineHeight: 1.5 }}>
                  {params.row.daysAbsent || '--'}
                </Typography>
              </Grid>
            );
          },
        },
        {
          field: 'milestoneHours',
          headerName: 'Total Hours',
          type: 'string',
          sortable: true,
          width: 250,
          renderCell: (params: any) => {
            return (
              <Grid container justifyContent={'left'} alignItems={'center'}>
                <Typography variant='button' sx={{ textTransform: 'capitalize', lineHeight: 1.5 }}>
                  {params.row.milestoneHours || '--'}
                </Typography>
              </Grid>
            );
          },
        },
        {
          field: 'enrollmentDate',
          headerName: 'Enrollment Date',
          type: 'string',
          sortable: true,
          width: 250,
          renderCell: (params: any) => {
            return (
              <Grid container justifyContent={'left'} alignItems={'center'}>
                <Typography variant='button' sx={{ textTransform: 'capitalize', lineHeight: 1.5 }}>
                  {params.row.enrollmentDate || '--'}
                </Typography>
              </Grid>
            );
          },
        },
        {
          field: 'completionDate',
          headerName: 'Completion Date',
          type: 'string',
          sortable: true,
          width: 250,
          renderCell: (params: any) => {
            return (
              <Grid container justifyContent={'left'} alignItems={'center'}>
                <Typography variant='button' sx={{ textTransform: 'capitalize', lineHeight: 1.5 }}>
                  {params.row.completionDate || '--'}
                </Typography>
              </Grid>
            );
          },
        },
      ];
    }
    return array;
  };

  return (
    <Box mt={2} pt={5}>
      <Grid container>
        <Grid item xs={12} sm={12} lg={12} mb={2}>
          <Typography variant='h5'>Report</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1} pt={0}>
        <Grid item xs={12} sm={12} lg={6}>
          <Autocomplete
            autoComplete={false}
            size='small'
            disableClearable
            options={ReportUtils.map((item: any) => ({
              title: item.title,
              id: item.id,
            }))}
            getOptionLabel={(option: any) => {
              return option.title;
            }}
            onChange={(event, object) => {
              clearInputs();
              setSelectedCaseLoadRole(null);
              setSelectedReport(object);
            }}
            renderInput={(params) => <TextField {...params} label='Select Report Type' />}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={6}>
          {selectedReport ? renderReportSubType() : null}
        </Grid>
      </Grid>

      {selectedCaseLoadRole ? renderNavigatorAndEmpLiaisons() : null}

      {selectedTrainer ? renderProgramTrackAndClient() : null}

      {selectedReport?.id === 2 ? renderProgramTrackAndNavigator() : null}

      <Grid container>
        <Grid item xs={12} sm={12} lg={12}>
          {listCheckpoint() ? (
            <>
              {selectedReport ? (
                <CustomTable
                  columns={getColsByReportTypeId()}
                  hideSearch={true}
                  rows={dataTable.length > 0 ? dataTable : []}
                  filterList={[]}
                  loading={loading}
                  onFilterListChange={() => {}}
                  onPaginationModelChange={setPaginationModel}
                  paginationModel={paginationModel}
                  primaryButtonLable=''
                  hidePrimaryBtn={true}
                  rowCount={metaInfo?.totalRecords || 0}
                  style={dataTable.length > 0 ? null : { height: '250px' }}
                  onRowClick={(params: any) => {
                    if (selectedReport?.id === 1) {
                      navigate('../clientInfo', {
                        state: { clientId: params.row.id, programTrackId: params.row.programTrackInfo?.id || null },
                      });
                    }
                  }}
                  title=''
                />
              ) : null}
            </>
          ) : null}

          {selectedReport?.id === 2 ? (
            <>
              <Box sx={{ mt: 4 }}>
                <Divider>
                  <Chip label='Case Notes' sx={{ fontWeight: 'bold' }} />
                </Divider>
              </Box>
              <ClientNotes clientId={selectedClient?.id} isReportPage={true} />
            </>
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Report;
