import React from 'react';
import * as yup from 'yup';
import { TextField, Grid, InputLabel, Box, Button } from '@mui/material';
import { Formik, type FormikHelpers } from 'formik';
import { ChevronRight, ChevronLeft } from '@mui/icons-material';
import { IBasicDetails } from '../utils';
import { COLORS } from '../../../../utils/colors';
import { checkIfEmailAlreadyExist } from '../../../../services/client.service';
import { toast } from 'react-toastify';

const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
const zipRegExp = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

const schema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  middleName: yup.string(),
  lastName: yup.string().required('Last Name is required'),
  address1: yup.string().required('Address is required'),
  address2: yup.string(),
  mobile: yup.string().max(50).required('Mobile Number is required'),
  state: yup.string(),
  homeNumber: yup.string().matches(phoneRegExp, 'Home Number is not valid').optional(),
  city: yup.string(),
  zip: yup.string().matches(zipRegExp, 'Zip Code is not valid'),
  workNumber: yup.string().matches(phoneRegExp, 'Work Number is not valid'),
  dob: yup.string().required('DoB is required'),
  ssn: yup.string().length(4),
  email: yup.string().email().required('Email is required'),
});

const styles = {
  formLabel: {
    color: COLORS.palette.black,
    marginBottom: '5px',
    '& .MuiInputLabel-asterisk': {
      color: 'red',
    },
  },
};

const BasicInfo = (props: any) => {
  const initialValues: IBasicDetails = {
    firstName: '',
    middleName: '',
    lastName: '',
    address1: '',
    mobile: '',
    address2: '',
    state: '',
    homeNumber: '',
    city: '',
    zip: '',
    workNumber: '',
    dob: '',
    ssn: '',
    email: '',
  };
  const getValuesOnBack = () => {
    return {
      firstName: props.data.firstName,
      middleName: props.data.middleName,
      lastName: props.data.lastName,
      address1: props.data.address1,
      mobile: props.data.mobile,
      address2: props.data.address2,
      state: props.data.state,
      homeNumber: props.data.homeNumber,
      city: props.data.city,
      zip: props.data.zip,
      workNumber: props.data.workNumber,
      dob: props.data.dob,
      ssn: props.data.ssn,
      email: props.data.email,
    };
  };

  const saveInfo = (values: IBasicDetails, { setSubmitting }: FormikHelpers<IBasicDetails>) => {
    (async () => {
      const result = await checkIfEmailAlreadyExist(values.email);
      if (!result) {
        props.handleNext(values);
        setSubmitting(false);
      } else {
        setSubmitting(false);
        toast.error('Email Already In Use!');
      }
    })();
  };

  return (
    <Box sx={{ width: '100%', mt: 5 }}>
      <Formik
        initialValues={props.isBackMode ? getValuesOnBack() : initialValues}
        onSubmit={saveInfo}
        validationSchema={schema}
        validateOnMount={true}>
        {({ values, handleSubmit, handleChange, handleBlur, touched, errors, isValid }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <InputLabel sx={styles.formLabel} required>
                  First name
                </InputLabel>
                <TextField
                  fullWidth
                  placeholder='First Name'
                  name='firstName'
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.firstName && Boolean(errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <InputLabel style={{ color: COLORS.palette.black }}>Middle name</InputLabel>
                <TextField
                  fullWidth
                  placeholder='Middle name'
                  name='middleName'
                  value={values.middleName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.middleName && Boolean(errors.middleName)}
                  helperText={touched.middleName && errors.middleName}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <InputLabel sx={styles.formLabel} required>
                  Last name
                </InputLabel>
                <TextField
                  fullWidth
                  placeholder='Last name'
                  name='lastName'
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.lastName && Boolean(errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                />
              </Grid>
              <Grid item xs={12} lg={8}>
                <InputLabel style={{ color: COLORS.palette.black }}>Address Line 1</InputLabel>
                <TextField
                  fullWidth
                  placeholder='Address Line 1'
                  name='address1'
                  value={values.address1}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.address1 && Boolean(errors.address1)}
                  helperText={touched.address1 && errors.address1}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <InputLabel sx={styles.formLabel} required>
                  Mobile Number
                </InputLabel>
                <TextField
                  fullWidth
                  placeholder='Mobile Number'
                  name='mobile'
                  value={values.mobile}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.mobile && Boolean(errors.mobile)}
                  helperText={touched.mobile && errors.mobile}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <InputLabel style={{ color: COLORS.palette.black }}>Residential Address Line 2</InputLabel>
                <TextField
                  fullWidth
                  placeholder='Address Line 2'
                  name='address2'
                  value={values.address2}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.address2 && Boolean(errors.address2)}
                  helperText={touched.address2 && errors.address2}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <InputLabel style={{ color: COLORS.palette.black }}>State</InputLabel>
                <TextField
                  fullWidth
                  placeholder='State'
                  name='state'
                  value={values.state}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.state && Boolean(errors.state)}
                  helperText={touched.state && errors.state}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <InputLabel style={{ color: COLORS.palette.black }}>Home Number</InputLabel>
                <TextField
                  fullWidth
                  placeholder='Home Number'
                  name='homeNumber'
                  multiline
                  value={values.homeNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.homeNumber && Boolean(errors.homeNumber)}
                  helperText={touched.homeNumber && errors.homeNumber}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <InputLabel style={{ color: COLORS.palette.black }}>City</InputLabel>
                <TextField
                  fullWidth
                  placeholder='City'
                  name='city'
                  value={values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.city && Boolean(errors.city)}
                  helperText={touched.city && errors.city}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <InputLabel style={{ color: COLORS.palette.black }}>Zip</InputLabel>
                <TextField
                  fullWidth
                  placeholder='Zip'
                  name='zip'
                  value={values.zip}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.zip && Boolean(errors.zip)}
                  helperText={touched.zip && errors.zip}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <InputLabel style={{ color: COLORS.palette.black }}>Work Number</InputLabel>
                <TextField
                  fullWidth
                  placeholder='Work Number'
                  name='workNumber'
                  value={values.workNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.workNumber && Boolean(errors.workNumber)}
                  helperText={touched.workNumber && errors.workNumber}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <InputLabel sx={styles.formLabel} required>
                  DOB
                </InputLabel>
                <TextField
                  name='dob'
                  type='date'
                  defaultValue={values.dob}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.dob && Boolean(errors.dob)}
                  helperText={touched.workNumber && errors.workNumber}
                  fullWidth
                  inputProps={{ max: new Date().toISOString().split('T')[0] }}
                />
                {touched.dob && Boolean(errors.dob) ? (
                  <p style={{ color: COLORS.result.error, fontSize: 13, paddingLeft: 10, paddingTop: 1 }}>
                    {errors.dob}
                  </p>
                ) : null}
              </Grid>
              {!props.isSelf ? (
                <Grid item xs={12} lg={4}>
                  <InputLabel sx={styles.formLabel} required>
                    Last 4 SSN #
                  </InputLabel>
                  <TextField
                    fullWidth
                    placeholder='Last 4 SSN #'
                    name='ssn'
                    value={values.ssn}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.ssn && Boolean(errors.ssn)}
                    helperText={touched.ssn && errors.ssn}
                  />
                </Grid>
              ) : null}
              <Grid item xs={12} lg={4}>
                <InputLabel sx={styles.formLabel} required>
                  Email
                </InputLabel>
                <TextField
                  fullWidth
                  placeholder='Email'
                  name='email'
                  type='email'
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', flexDirection: 'row', mt: 5 }}>
              <Button
                color='inherit'
                disabled={true}
                onClick={props.handleBack}
                sx={{ mr: 1 }}
                startIcon={<ChevronLeft />}
                size={'large'}>
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button
                type='submit'
                variant='contained'
                // @ts-ignore
                color='darkModePrimary'
                size='large'
                disabled={!isValid}
                endIcon={<ChevronRight />}>
                {'Next'}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default BasicInfo;
