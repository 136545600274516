import { UserModel } from './../store/user/userModel';
import { setStaffList } from './../store/user/userAction';
import { db, functions } from '../config/firebaseApp';
import { collection, doc, getDoc, getDocs, onSnapshot, query, where } from 'firebase/firestore';
import { AppDispatch } from '../store/store';
import { httpsCallable } from 'firebase/functions';
import { IResponse } from './common';
import { toast } from 'react-toastify';
import { userAccountStatus } from '../utils/userUtils';
import { UserRolesEnum } from '../utils/rolesEnum';

export interface IStaffMetaInfo {
  totalRecords: number;
  currentPage: number;
  staffs: Array<Object>;
  totalPages: number;
}

export const addNewUser = async (user: UserModel) => {
  const response = await httpsCallable(functions, 'createNewUser')(user);

  return response.data;
};

export const editUser = async (user: UserModel) => {
  const response = await httpsCallable(functions, 'editUser')(user);

  return response.data;
};

export const forgotPassword = async (email: string) => {
  const response = await httpsCallable(functions, 'forgotPassword')({ email });

  return response.data as Promise<IResponse>;
};
export const getPasswordResetLink = async (email: string) => {
  const response = await httpsCallable(functions, 'generatePasswordResetLink')({ email });

  return response.data;
};

export const updateUsersNewAccountStatus = async (userId: string, role: any) => {
  const response = await httpsCallable(functions, 'updateUsersNewAccountStatus')({ userId: userId, role: role });
  return response.data as Promise<IResponse>;
};

export const fetchStaffList = () => async (dispatch: AppDispatch) => {
  const staffRef = collection(db, 'staff');
  const snapshots = await getDocs(staffRef);
  const staffList: any[] = snapshots.docs
    .filter((doc) => doc.data().account !== userAccountStatus.DELETED) // Filter out staff with deleted status
    .map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

  dispatch(setStaffList(staffList));
};

export const getNavigatorList = async () => {
  try {
    const staffRef = collection(db, 'staff');
    const q = query(staffRef, where('role', '==', 'navigator'));
    const querySnapshot = await getDocs(q);

    const navigatorList: any = [];
    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.account !== userAccountStatus.DELETED) {
          navigatorList.push({ id: doc.id, ...data });
        }
      });
    }
    return navigatorList;
  } catch (err: any) {
    toast.error('Error Retrieving Navigators!');
  }
};

export const getTrainerList = async () => {
  try {
    const staffRef = collection(db, 'staff');
    const q = query(staffRef, where('role', '==', 'trainer'));
    const querySnapshot = await getDocs(q);

    const trainerList: any = [];
    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.account !== userAccountStatus.DELETED) {
          trainerList.push({ id: doc.id, ...data });
        }
      });
    }
    return trainerList;
  } catch (err: any) {
    toast.error('Error Retrieving Trainers!');
  }
};

export const getUserInfoById = async (userId: any) => {
  try {
    if (!userId) {
      throw new Error();
    }

    const staffDocRef = doc(db, 'staff', userId);
    const staffDoc = await getDoc(staffDocRef);

    if (staffDoc.exists()) {
      return { id: staffDoc.id, ...staffDoc.data() };
    } else {
      return {};
    }
  } catch (error: any) {
    return {};
  }
};

export const getMultipleUserInfoByUserIds = async (ids: string[]) => {
  try {
    if (!ids.length) {
      throw new Error();
    }

    const staffRef = collection(db, 'staff');
    const querySnapshot = await getDocs(query(staffRef));
    const documents = querySnapshot.docs.filter((doc) => ids.includes(doc.id));

    const staffList: any = [];

    documents.forEach((doc) => {
      staffList.push({ id: doc.id, ...doc.data() });
    });

    return staffList;
  } catch (err: any) {
    return [];
  }
};

export const deleteStaff = async (id: string) => {
  try {
    const response = await httpsCallable(functions, 'deleteStaff')({ id: id });
    return response.data;
  } catch (error: any) {
    //err catch
  }
};

export const getStaffList = async () => {
  try {
    const staffRef = collection(db, 'staff');
    const snapshots = await getDocs(staffRef);
    const staffList: any[] = snapshots.docs
      .filter((doc) => doc.data().account !== userAccountStatus.DELETED)
      .map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
    return staffList;
  } catch (error: any) {
    return [];
  }
};

export const getStaffInfoByClientId = async (clientId: string) => {
  try {
    const clientRef = doc(db, 'clients', clientId);
    const clientDoc = await getDoc(clientRef);

    if (clientDoc.exists()) {
      const clientData = clientDoc.data();
      const navigatorId = clientData.assignedTo;
      const caseManagerId = clientData.createdBy;
      const staffInfo = await getMultipleUserInfoByUserIds([navigatorId, caseManagerId]);
      return staffInfo;
    }
  } catch (error: any) {
    return [];
  }
};

export const getStaffsByQuery = async (filterRole: string, page: number) => {
  try {
    const staffRef = collection(db, 'staff');
    const querySnapshot = await getDocs(staffRef);
    const pageSize = 20;
    const offset = (page - 1) * pageSize;

    if (!querySnapshot.empty) {
      const staffList: any[] = [];
      querySnapshot.forEach((doc) => {
        if (doc.data().account !== userAccountStatus.DELETED) {
          const data = { id: doc.id, ...doc.data() };
          staffList.push(data);
        }
      });

      let filteredStaff: any[] = [];

      if (filterRole !== 'all') {
        filteredStaff = staffList.filter((item: any) => item.role.toLowerCase() === filterRole.toLowerCase());
      } else {
        filteredStaff = [...staffList];
      }

      const totalPages = Math.ceil(filteredStaff.length / pageSize);
      const limitedStaffs = filteredStaff.slice(offset, offset + pageSize);
      const result: IStaffMetaInfo = {
        totalRecords: filteredStaff.length,
        currentPage: page,
        staffs: limitedStaffs,
        totalPages: totalPages,
      };
      return result;
    } else {
      return {
        totalRecords: 0,
        currentPage: page,
        staffs: [],
        totalPages: 0,
      };
    }
  } catch (err: any) {
    toast.error('Error Retrieving Info Session List!');
  }
};

export const searchStaff = async (searchQuery: string, filterRole: string, page: number) => {
  try {
    const response: any = await httpsCallable(functions, 'searchStaffByName')({ query: searchQuery });

    const staffData = response.data.data;
    const staffIds: any = [];
    let staffList = [];
    const pageSize = 20;
    const offset = (page - 1) * pageSize;

    if (staffData.length > 0) {
      for (const data of staffData) {
        staffIds.push(data.id);
      }

      staffList = await getMultipleUserInfoByUserIds(staffIds);

      if (staffList.length > 0) {
        let filteredStaff: any[] = [];

        if (filterRole !== 'all') {
          filteredStaff = staffList.filter((item: any) => item.role.toLowerCase().includes(filterRole.toLowerCase()));
        } else {
          filteredStaff = [...staffList];
        }

        const totalPages = Math.ceil(filteredStaff.length / pageSize);
        const limitedStaffs = filteredStaff.slice(offset, offset + pageSize);
        const result: IStaffMetaInfo = {
          totalRecords: filteredStaff.length,
          currentPage: page,
          staffs: limitedStaffs,
          totalPages: totalPages,
        };
        return result;
      } else {
        return {
          totalRecords: 0,
          currentPage: page,
          staffs: [],
          totalPages: 0,
        };
      }
    }
  } catch (error) {
    toast.error('Error Searching Staff!');
  }
};

export const resetStaffPassword = async (data: any) => {
  const response = await httpsCallable(functions, 'resetStaffPassword')(data);

  return response.data;
};

export const getEmploymentLiaisonList = async (callback: Function) => {
  try {
    const staffRef = collection(db, 'staff');
    const q = query(staffRef, where('role', '==', 'employmentLiaison'));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const liaisonList: any = [];
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.account !== userAccountStatus.DELETED) {
            liaisonList.push({ id: doc.id, ...data });
          }
        });
      }
      callback(liaisonList);
    });
    return unsubscribe;
  } catch (err: any) {
    toast.error('Error Retrieving Employment Liaisons List!');
  }
};

export const getEmployerList = async () => {
  try {
    const staffRef = collection(db, 'staff');
    const q = query(staffRef, where('role', '==', UserRolesEnum.EMPLOYER));
    const querySnapshot = await getDocs(q);

    const employerList: any = [];
    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.account !== userAccountStatus.DELETED) {
          employerList.push({ id: doc.id, ...data });
        }
      });
    }
    return employerList;
  } catch (err: any) {
    toast.error('Error Retrieving Employers!');
  }
};

export const getUserFullNameById = async (userId: string) => {
  try {
    if (userId) {
      const staffDocRef = doc(db, 'staff', userId);
      const staffDoc = await getDoc(staffDocRef);

      if (staffDoc.exists()) {
        return `${staffDoc.data().firstName} ${staffDoc.data().lastName}`;
      } else {
        return '';
      }
    }
    return '';
  } catch (error) {
    console.log(error);
    return '';
  }
};
